import React, { useRef, useState, useEffect } from 'react'
import './Home.scss'
import { useSelector, useDispatch } from "react-redux";
import allActions from '../../actions'
import { Link } from 'react-router-dom'
import Header from '../Header/Header'
import FileTypes from '../FileTypes/FileTypes';
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAudio, faVideo, faFile, faImage, faBoxArchive, faBook, faCaretDown, faCaretUp, faMobileScreen, faFileLines, faShield, faBoltLightning } from '@fortawesome/free-solid-svg-icons'
import Footer from '../Footer/Footer';
import { Helmet } from 'react-helmet';
import FileUploader from '../FileUploader/FileUploader';

function Home(props) {

sessionStorage.clear();
    let firstInput = sessionStorage.getItem("firstInput") || ""
    let secondInput = sessionStorage.getItem("secondInput") || ""
    const dispatch = useDispatch();
    const location = useLocation();
    const fileType = location.state?.fileType || "File";
    const bannerText = location.state?.bannerText || "FilesMonkey is a free online file converter. Our app supports all types of image, document, video, audio, Ebook and archive formats which you can use to convert to any other format of your choice for absolutely free."
    const [showFileTypes, setShowFileTypes] = useState(false);
    const [showFileTypesTwo, setShowFileTypesTwo] = useState(false);
    const menu = useSelector(state => state.menuReducer)
    const mode = useSelector(state => state.darkModeReducer)


    const handleShowFileTypes = () => {
        setShowFileTypes(!showFileTypes)
        setShowFileTypesTwo(false)
    }
    const handleShowFileTypesTwo = () => {
        setShowFileTypesTwo(!showFileTypesTwo)
        setShowFileTypes(false)
    };

    const toggleMenu = (e) => {
        dispatch(allActions.menuActions())
        dispatch(allActions.inputActions.inputFile([]))
    }

    const content = (<div className="menu-container" style={mode ? { backgroundColor: '#13315c' } : { backgroundColor: '#0582ca' }}>
        <div className="first-row">
            <div className="menu-item" onClick={toggleMenu}><Link to="/image-converter" state={{ fileType: "Image", bannerText: "Conver your image files for free with FilesMonkey. You can choose any format of your choice from JPEG, PNG, BMP, GIF, SVG, TIFF and many others." }} style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faImage} size="xl" /><span className="type-text">Image Converter</span></Link></div>
            <div className="menu-item" onClick={toggleMenu}><Link to="/document-converter" state={{ fileType: "Document", bannerText: "Conver your document files for free with FilesMonkey. You can choose any format of your choice from DOC, DOCX, PDF, PPTX, HTML, XML and many others." }} style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faFile} size="xl" /><span className="type-text">Document Converter</span></Link></div>
            <div className="menu-item" onClick={toggleMenu}><Link to="/audio-converter" state={{ fileType: "Audio", bannerText: "Conver your audio files for free with FilesMonkey. You can choose any format of your choice from JPEG, PNG, BMP, GIF, SVG, TIFF and many others." }} style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faFileAudio} size="xl" /><span className="type-text">Audio Converter</span></Link></div>
        </div>
        <div className="second-row">
        <div className="menu-item" onClick={toggleMenu}><Link to="/video-converter" state={{ fileType: "Video", bannerText: "Convert your video files for free with FilesMonkey. You can choose any format of your choice from MP4, MOV, AVI, WVM, FLV, WebM, MKV and many others" }} style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faVideo} size="xl" /><span className="type-text">Video Converter</span></Link></div>
            <div className="menu-item" onClick={toggleMenu}><Link to="/archive-converter" state={{ fileType: "Archive", bannerText: "Convert your archive files for free with FilesMonkey. You can choose any format of your choice from ZIP, RAR, Tar.GZ, 7Z, JAR, TGZ and many others" }} style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faBoxArchive} size="xl" /><span className="type-text">Archive Converter</span></Link></div>
            <div className="menu-item" onClick={toggleMenu}><Link to="/ebook-converter" state={{ fileType: "Ebook", bannerText: "Convert your ebook files for free with FilesMonkey. You can choose any format of your choice from DJVU, TCR, MOBI, PRC and many others" }} style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faBook} size="xl" /><span className="type-text">Ebook Converter</span></Link></div>
        </div>
    </div>);
    return (
        <>
        <Helmet>
                <html lang="en" />
                <title>FilesMonkey - Free Online File Converter</title>
                <meta name="description" content="convert files with FilesMonkey" />
            </Helmet>
            <div className="sticky">
                <Header />
            </div>
            {menu ? (content) : ""}
            <div className="main-container">
                {!menu ? (<div className="banner" style={mode ? { backgroundColor: '#13315c' } : { backgroundColor: '#0582ca' }}>
                    <div className="first-column">
                        <h1 className="file-type">Free Online {fileType} Converter</h1>
                        <p className="banner-text">
                            {bannerText}
                        </p>
                    </div>
                    <div className="second-column">
                        {showFileTypes || showFileTypesTwo ? (<div className="nav-menu"><FileTypes showFileTypes={showFileTypes} setShowFileTypes={setShowFileTypes} showFileTypesTwo={showFileTypesTwo} setShowFileTypesTwo={setShowFileTypesTwo} /></div>) : ""}
                        <span className="convert-text">Convert</span>
                        {showFileTypes ? (<button className="dropdown-list" onClick={handleShowFileTypes}><FontAwesomeIcon icon={faCaretUp} size="xl" /><span className="small-text">{!firstInput ? "..." : firstInput}</span></button>) : (<button className="dropdown-list" onClick={handleShowFileTypes}><FontAwesomeIcon icon={faCaretDown} size="xl" /><span className="small-text">{!firstInput ? "..." : firstInput}</span></button>)}
                        <span className="convert-text">to</span>
                        {showFileTypesTwo ? (<button className="dropdown-list" onClick={handleShowFileTypesTwo}><FontAwesomeIcon icon={faCaretUp} size="xl" /><span className="small-text">{!secondInput ? "..." : secondInput}</span></button>) : (<button className="dropdown-list" onClick={handleShowFileTypesTwo}><FontAwesomeIcon icon={faCaretDown} size="xl" /><span className="small-text">{!secondInput ? "..." : secondInput}</span></button>)}
                    </div>
                </div>) : ""}
                <div><FileUploader /></div>
                <div className="features-box" style={mode ? { backgroundColor: '#333533' } : { backgroundColor: '#e9ecef' }}>
                    <div className="features-first-row">
                        <div className="content-box">
                            <span className="icon" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}><FontAwesomeIcon icon={faFileLines} size="xl" /></span>
                            <span className="heading" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}>
                                300+ formats supported
                            </span>
                            <p style={mode ? { color: '#c0c0c0' } : { color: '#212529' }}>
                                We support more than 300 file formats. You can convert from any one of those to any of the file format of your choice.
                            </p>
                        </div>
                        <div className="content-box">
                            <span className="icon" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}><FontAwesomeIcon icon={faShield} size="xl" /></span>
                            <span className="heading" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}>
                                Data Security
                            </span>
                            <p style={mode ? { color: '#c0c0c0' } : { color: '#212529' }}>
                                Your uploaded and converted files are deleted after 24 hours automatically and irreversibly. This implies that you can delete your files manually on your own anytime before they are deleted automatically.
                            </p>
                        </div>
                    </div>
                    <div className="features-first-row">
                        <div className="content-box">
                            <span className="icon" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}><FontAwesomeIcon icon={faBoltLightning} size="xl" /></span>
                            <span className="heading" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}>
                                Lightning fast conversions
                            </span>
                            <p style={mode ? { color: '#c0c0c0' } : { color: '#212529' }}>
                                You will definitely feel it when you use our application. This app helps you convert your files at blazing fast speed and helps you save lots of time.
                            </p>
                        </div>
                        <div className="content-box">
                            <span className="icon" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}><FontAwesomeIcon icon={faMobileScreen} size="xl" /></span>
                            <span className="heading" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}>
                                All screens supported
                            </span>
                            <p style={mode ? { color: '#c0c0c0' } : { color: '#212529' }}>
                                FilesMonkey is a browser based application and you do not need to install any other additional software. You can access it from any of your devices using your web browser.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Home