import darkModeReducer from "./darkModeReducers";
import menuReducer from "./menuReducers";
import inputReducers from "./inputReducers";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
    darkModeReducer,
    menuReducer,
    inputReducers
})

export default rootReducer;