const firstInput = (first_input_obj) => {
    return {
        type: "FIRST_INPUT",
        payload: first_input_obj
    }
}

const secondInput = (second_input_obj) => {
    return {
        type: "SECOND_INPUT",
        payload: second_input_obj
    }
}

const inputFile = (input_file_obj) => {
    return {
        type: "INPUT_FILE",
        payload: input_file_obj
    }
}



export default {
    firstInput,
    secondInput,
    inputFile,
}