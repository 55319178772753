export default [
    { name: "art", description: "The ART format (file extension . art) holds a single still image that has been highly compressed. The format was designed to facilitate the quick downloading of images, among other things." },
    { name: "arw", description: "ARW files (. arw) are raw photos, or digital negatives, that are created by most camera models. They're called raw files because they contain unprocessed image data." },
    { name: "avs", description: "An AVS file is a configuration file used by Advanced Visualization Studio (AVS), an audio visualizer for the Nullsoft Winamp media player. It contains settings for animations, such as beats and transitions, that are played based on the music played within Winamp." },
    { name: "bmp", description: "A BMP file stands for bitmap and is commonly pronounced bump. Microsoft originally developed the BMP format for its Windows operating system to maintain the resolution of digital images across different screens and devices." },
    { name: "bpg", description: "BPG (Better Portable Graphics) is a new image format. Its purpose is to replace the JPEG image format when quality or file size is an issue. Its main advantages are: High compression ratio. Files are much smaller than JPEG for similar quality." },
    { name: "brf", description: "Braille Ready Format (BRF) is a simple text file representing finished braille pages." },
    { name: "cals", description: "The CALS Raster file format is a standard for the interchange of graphics data. It was developed by the United States Department of Defense (DoD) as part of the Continuous Acquisition and Life-cycle Support (CALS) initiative. It defines a standard storing raster (bit-mapped) image data, either uncompressed or compressed using CCITT Group 4 compression." },
    { name: "cgm", description: "The Computer Graphics Metafile (CGM) format family is a family of free and open international standard file formats for 2D vector graphics, raster graphics, and text, which are defined by the current ISO/IEC 8632-1:1999 standard." },
    { name: "cr2", description: "A file with the CRW file extension is a Canon RAW CIFF image file. These files are unprocessed and uncompressed images taken with a Canon digital camera. CRW files are structured similarly to the TIFF file format." },
    { name: "crw", description: "CRW refers to one of the various file extensions used by Canon cameras for their raw image files. These files are not yet processed and contain the most information possible from the camera’s intake." },
    { name: "cur", description: "A CUR file is a static Microsoft Windows cursor file format. Basically, they are stationary images identical to ICO (icon) files in every way except the extension. Both CUR and ICO are established on the basis of the Device-Independent Bitmap DIB (Device-Independent Bitmap) specification." },
    { name: "cut", description: "A cut file is a digital image that has been designed and saved to be used with a cutting machine. SVG is a digital file format that stands for Scalable Vector Graphics, and it is the most common format for cut files." },
    { name: "dcm", description: "The DCM file extension is used for DICOM which stands for Digital Imaging and Communications in Medicine. This is the common file format used to store medical imaging data when a patient undergoes a CT, MRI, PET or UltraSound." },
    { name: "dcr", description: "DCR is short for Digital Camera Raw, the raw image format created by Kodak. It is used in Kodak SLR cameras to store uncompressed and lossless version of the images taken." },
    { name: "dcx", description: "DCX is a simple extension to the PCX image format that supports bundling multiple PCX images in one file. DCX files were mainly used for FAX documents in early FAX software." },
    { name: "dib", description: "A Device-Independent bitmap (DIB) is a raster image file that is similar in structure to the standard Bitmap files(BMP/image/bmp/)). It contains a color table that describes the mapping of RGB colors to the pixel values." },
    { name: "dng", description: "DNG means Digital Negative. It's a type of raw file format used in digital photography, developed on the TIFF 6.0 format. With an abundance of different cameras and editing software on the market." },
    { name: "emf", description: "An EMF file is a Windows file format designed to store large amounts of image detail for high-quality printing. Created as an improvement on the traditional Windows Metafile (WMF) an EMF delivers crisper details and a deeper, more varied range of colours — making it ideal for graphics printing." },
    { name: "fax", description: "A file with the FAX file extension is an image of a fax, usually in the TIFF format. It's used by some fax software and makes it easy for recipients to view the fax." },
    { name: "gif", description: "GIF stands for Graphics Interchange Format. GIF is a raster file format designed for relatively basic images that appear mainly on the Internet. Each file can support up to 8 bits per pixel and can contain 256 indexed colours." },
    { name: "hdr", description: "An HDR file is a High Dynamic Range (HDR) raster image file format for storing digital camera photos. It allows photo editors to enhance the color and brightness of digital images that have limited dynamic range." },
    { name: "hrz", description: "Contains character hair information in a compressed format; used by Poser, a 3D figure modeling program; may include either strand-based or transparency-mapped hair, which can be applied to Poser 3D models. Uncompressed Poser hair files use the . HR2 extension." },
    { name: "ico", description: "The ICO file format is an image file format for computer icons in Microsoft Windows. ICO files contain one or more small images at multiple sizes and color depths." },
    { name: "info", description: "An INFO file is a formatted information document created by Texinfo, a GNU Project help document generation tool. It stores documentation in a book format with chapters, sections, and subsections and can contain up to four layers of depth." },
    { name: "jng", description: "JNG is a lossy single-image member of the PNG (Portable Network Graphics) format family. It encapsulates a JPEG datastream in PNG-style chunks, along with an optional alpha channel and ancillary chunks that carry color-space information and comments." },
    { name: "jpeg", description: "A JPEG is a type of image format that is saved using the method of lossy compression. The output image, as result of compression, is a trade-off between storage size and image quality." },
    { name: "mat", description: "A MAT-File is self-describing from a technical perspective, allowing an application that understands the format to interpret the structure and contents without supplementary information." },
    { name: "mng", description: "MNG is a multiple-image member of the PNG (Portable Network Graphics) format family. It can contain animations, slide shows, or complex still frames, comprised of multiple PNG or JNG single-image datastreams." },
    { name: "mono", description: "The Mono format is a file format for storing monochrome (black-and-white) bitmap images. It uses a simple uncompressed raster format, where each pixel is represented by a single bit that is either set to 1 (black) or 0 (white)." },
    { name: "mpc", description: "MPC files contain compressed audio files. The MPC file format is based on an improved variation of the MPEG-1 Layer-2 algorithm. While MPC files are not as popular as MP3 files, they have a better sound quality than what MP3 files provide." },
    { name: "mrw", description: "MRW files are raw images captured by various Minolta digital cameras. They store image data in an uncompressed format that preserves the highest possible image quality." },
    { name: "mtv", description: "An MTV file is a video saved in a specialized format used by some Chinese portable MP4 video players. It contains MP3 audio and a series of images that are shown during playback, at a rate of 12 frames per second." },
    { name: "mvg", description: "MVG files mostly belong to ImageMagick by ImageMagick Studio LLC. ImageMagick is a software suite to create, edit, and compose bitmap images. It can read, convert and write images in a large variety of formats." },
    { name: "nef", description: "An NEF file is a Nikon Raw Image file. It is an abbreviation for Nikon Electronic Format, and is used solely on Nikon cameras." },
    { name: "orf", description: "An ORF file is a RAW photo taken with an Olympus digital camera. It is saved in the Olympus RAW Format (ORF), which stores image data exactly as captured by the camera's sensor." },
    { name: "otb", description: "The OTA Bitmap format is a monochrome, uncompressed format using one bit per pixel. As the format was designed for cellular phones, there is no standard computer format. It may be stored as a binary file or as hex (usually without spaces) in a text file." },
    { name: "p7", description: "A P7 file contains an X.509 public key certificate saved in the PKCS #7 cryptography message syntax of the Public-Key Cryptography Standards (PKCS). The file's contents are saved in raw DER (Distinguished Encoding Rules) or Privacy-enhanced Electronic Mail Base64 format." },
    { name: "palm", description: "A PALM file is a bitmap image created by a Palm OS mobile device. It may contain a logo, clipart, photo, or other image meant to be shown in a Palm OS application." },
    { name: "pam", description: "The PAM image format is a lowest common denominator 2 dimensional map format. It is designed to be used for any of myriad kinds of graphics, but can theoretically be used for any kind of data that is arranged as a two dimensional rectangular array." },
    { name: "pbm", description: "The portable bitmap format is a lowest common denominator monochrome file format. It was originally designed to make it reasonable to mail bitmaps between different types of machines using the typical stupid network mailers we have today." },
    { name: "pcx", description: "PCX, an acronym for Picture Exchange, is a raster image file type, which means that it uses colour pixels to display images. The other main type of image format is vector, which creates visuals using a complex structure of points, lines, graphs and formulas." },
    { name: "pes", description: "The PES embroidery file is a design file that contains instructions for the embroidery/sewing machines. It was developed by Brother Industries for their embroidery machines but were later formalized as general file format." },
    { name: "pfa", description: "A file with .pfa extension is a Printer Font ASCII version of Adobe’s Type 1 font and contains font’s glyph data. The file is in human readable format and can be opened in any text editor." },
    { name: "pfb", description: "A file with .pfa extension is a Printer Font ASCII version of Adobe’s Type 1 font and contains font’s glyph data. The file is in human readable format and can be opened in any text editor." },
    { name: "pfm", description: "A file with .pfm extension is a Printer Font Metrics file that contains character measurements included in the font file." },
    { name: "pgm", description: "PGM (Portable Grey Map) files store grayscale 2D images. Each pixel within the image contains only one or two bytes of information (8 or 16 bits). While that might not sound like a lot of information, PGM files can hold tens of thousands of shades." },
    { name: "picon", description: "PICON format is a type of file format commonly associated with digital television systems. PICON stands for 'Program Information and Control'. PICON files typically contain graphical images or icons that represent television channels or programs." },
    { name: "pict", description: "PICT is a graphics file format introduced on the original Apple Macintosh computer as its standard metafile format. It allows the interchange of graphics (both bitmapped and vector), and some limited text support." },
    { name: "pix", description: "The PIX file format is a raster image format that is commonly used in the Microsoft Windows operating system. It is also known as the Microsoft Pixel Format. It supports a wide range of color depths and can be used to store both uncompressed and compressed images." },
    { name: "png", description: "Portable Network Graphics is a raster-graphics file format that supports lossless data compression. PNG was developed as an improved, non-patented replacement for Graphics Interchange Format." },
    { name: "pnm", description: "The PNM (Portable Any Map) format is a family of image file formats used for portable images, which are maps of pixel values that can represent grayscale or color data. The PNM format family includes three types of file formats: PBM (Portable BitMap), PGM (Portable GrayMap), and PPM (Portable PixMap)." },
    { name: "ppm", description: "The PPM format was emerged in the late 1980s to make sharing images between different platforms easier. Each PPM file uses a text format to store information about a particular image. Within each file, every pixel has a specific number (from 0 to 65536) and information about the height and width of an image, plus any whitespace data." },
    { name: "psb", description: "PSB, also called Photoshop Big or Photoshop Large Document Format, is a proprietary image file format used by Adobe Photoshop. PSB files have the extension .psb, and can store a maximum image size of 300,000 pixels in each dimension." },
    { name: "psd", description: "Short for Photoshop document, PSD is the default file format of documents created in Adobe Photoshop. The PSD format allows a Photoshop user to save all the information needed to continue editing the file later." },
    { name: "ptif", description: "The PTIF (Portable Tile Image Format) is an image file format designed for storage of large, high-resolution images. It is a derivative of the TIFF (Tagged Image File Format) and is designed to address the limitations of TIFF for the storage of large images. PTIF is based on a tiled structure, which allows for efficient random access to image data." },
    { name: "pwp", description: "PWP is an extension given to files related to older image organization software called PhotoWorks. PWP files were used to store color graphics. This format was functionally similar to SWF files. PWP files are files, the most common use of which was their posting and making them available for download via the Internet." },
    { name: "rad", description: "Files in the .rad format are 3D image files used by an application called .rad, developed by Valve. This program is integrated with command line functionalities which can be used to create, modify or edit 3D lighting atributes of BSP projects." },
    { name: "raf", description: "An RAF file is an image captured in the RAW Fujifilm (RAF) format by select Fujifilm digital cameras, such as the FinePix F550 EXR and X100V models. It stores image data exactly how the camera's sensor captured it along with metadata, such as camera settings and format version." },
    { name: "rgf", description: "Graphics file created by LEGO MINDSTORMS EV3, a program that enables you to create and program LEGO robots; contains an image edited by the Image Editor tool in the LEGO MINDSTORMS EV3 application; includes text boxes, shapes, drawings, and other editing tools." },
    { name: "rla", description: "RLA (Run-Length Encoding Algorithm) is a raster image file format developed by Wavefront Technologies for storing images and associated metadata. It is primarily used in the computer graphics industry for exchanging data between different 3D modeling and animation software." },
    { name: "rle", description: "Files with the RLE extension are used for storing bitmap images. The data in such an image is encoded in a way that reduces the file size. It consists in that repeated data is represented by a repetition counter, eg the string cccccccc is coded as 8c." },
    { name: "sct", description: "The Scitex Continuous Tone file format was developed by Scitex and is used for high-end image processing. The SCT/CT files are used primarily to store CMY and CMYK color space for the image, and contain no compression." },
    { name: "sfw", description: "SFW is a file extension that is used by Seattle Film Works formerly known as Photo Works. These files are used to store bitmap images. SFW is a proprietary graphics format." },
    { name: "sgi", description: "SGI is the extension given to files that are used to store raster images. This format was developed by Silicon Graphics and was used by the company's graphic workstations. SGI files are files that can store both black and white, grayscale and color images." },
    { name: "sun", description: "A SUN file is a bitmap image saved in a proprietary format created by Sun Microsystems. It contains a photo or graphic. Workstations that use the SunOS operating system save images as SUN files." },
    { name: "svg", description: "An SVG file is an image saved in the Scalable Vector Graphic format. The vector graphic it contains can be scaled to any size without losing quality. For this reason, digital illustrators often save logos, charts, and other graphics intended to be shown at a variety of sizes as SVG files." },
    { name: "tga", description: "A TGA file is an image saved in the Targa raster graphic format designed by Truevision. It supports 8, 16, 24, or 32 bits per pixel at a maximum of 24 bits for RGB colors and an 8-bit alpha channel. TGA files are used for various types of images, such as digital photos and textures referenced by 3D video games." },
    { name: "tiff", description: "A TIFF file is a graphics container that stores raster images in the Tagged Image File Format (TIFF). It contains high-quality graphics that support color depths from 1 to 24-bit and supports both lossy and lossless compression. TIFF files also support multiple layers and pages." },
    { name: "tim", description: "TIM, or PSX TIM, is an uncompressed raster image file format associated with the Sony PlayStation family of video game consoles. It supports 4- and 8-bit paletted images, and 15- and 24-bit full color images." },
    { name: "ttf", description: "A TTF file is a font that can be installed in macOS, Windows, and other platforms. It is saved in the TrueType font format, which was created by Apple. TrueType fonts can be resized to any size without losing quality and look the same when printed as they do on the screen." },
    { name: "ubrl", description: "The Unicode Braille (uBrl) file format is a standard for representing Braille characters using Unicode encoding. It is designed to facilitate the exchange and storage of Braille content in a digital format. The uBrl format allows for easy integration of Braille text into various applications and systems, ensuring accessibility for individuals with visual impairments." },
    { name: "uil", description: "User Interface Language (UIL) is used to describe the initial state of a user interface for a widget-based application. UIL describes the widgets used in the interface, the resources of those widgets, and the callbacks of those widgets. A UIL file is compiled into a user interface definition (UID) file using the uil command or the Uil callable compiler function." },
    { name: "uyvy", description: "The “UYVY” file extension is related to a YUV 16-bit image format, YUV 16Bits Interleaved.YUV considering only as an input file format. Actually, bitmap images in YUV 16-bit image format are files with extension UYVY.UYVY file extension rarely used by or supported in graphics viewers or editors." },
    { name: "vacar", description: "The VACAR format is used in the field of computer-aided design (CAD) and computer-aided engineering (CAE). VACAR stands for 'Virtual Assembly Computer-Aided Representation'. This format is specifically designed to capture and store data related to virtual assemblies of mechanical components." },
    { name: "viff", description: "VIFF (Visualization Image File Format) is an uncompressed file format for storing 2D and 3D images. It was developed at the Danish Technical University and is commonly used in scientific and engineering applications. VIFF can store images with up to four channels (red, green, blue, and alpha)." },
    { name: "wbpm", description: "WBMP, short for Wireless Bitmap, is an application protocol specifically designed for processing raster images in wireless environments. Files with the .wbmp extension typically store black and white raster images." },
    { name: "wpg", description: "WPG (WordPerfect Graphics) is a vector graphics file format used by WordPerfect, a word processing application. It was created in 1992 and can be used to store images and drawings created within WordPerfect or imported from other programs." },
    { name: "x3f", description: "X3F is a raw image file format used by Sigma cameras. It is based on the TIFF format and uses a proprietary compression algorithm to store raw image data." },
    { name: "xbm", description: "XBM is an image format with the source containing image data in the form of plain binary text. The bitmap image's pixels support different shades of a single color. They were created as a format for storing images of icons and cursors displayed on the Graphical User Interface of the UNIX-based X Windows System." },
    { name: "xcf", description: "XCF is a file extension for an image file native to GIMP (freely distributed image editing program). Similar to a Photoshop Document (PSD), XCF files support saving layers, channels, transparency, paths and guides, yet don’t support saving the undo history." },
    { name: "xmp", description: "XMP (Extensible Metadata Platform) is a file format that allows for the embedding of metadata within digital assets, such as images, audio files, and documents. It was developed by Adobe Systems in 2001 to provide a universal way to describe metadata across different file types and platforms." },
    { name: "xwd", description: "XWD (X Window Dump) is a bitmap image format used to store screen captures or screenshots taken from an X Window System server. It is a simple format that stores pixel data, width and height of the image, the number of bits per pixel, and the byte order of the machine that generated the dump." },
    { name: "dxf", description: "DXF was first introduced for AutoCAD. It was 1982 when it was created. This format is usually applicable for interchanging graphics inputs between SAPR programs. It has access to vector graphics in binary or ASCII." },
    { name: "eps", description: "EPS is a convenient file format for the transfer of text and images files. Publishers frequently use them as it can run in all operating systems. Graphics can be sent and received within different PostScript files." },
    { name: "pcd ", description: "A PCD file is an image saved in the proprietary Kodak Photo CD (PCD) format. It stores a digital copy of an actual photograph that has been scanned using Kodak's photo processing hardware and stored on a compact disc (CD)." },
    { name: "pct", description: "A PCT file is an image saved in the Macintosh PICT format, which Apple developed in 1984. PCT files can contain vector or raster images. The PCT format has been deprecated, so PCT files are uncommon." },
    { name: "wmf", description: "A WMF file is a vector graphic saved in the proprietary Windows Metafile Format (WMF) format developed by Microsoft. It contains a series of metafile records that store drawing operations, including commands for drawing lines, circles, and rectangles that make up the graphic." },
    { name: "jpg", description: "A JPG file is an image saved in the JPEG format. JPG files commonly contain digital photographs, drawings, or graphics. On most devices, you can double-click or double-tap a JPG file to open it in your default image viewer." },
    { name: "ai", description: "An AI file is a vector graphic saved in the Adobe Illustrator Artwork (AI) format. It is created by Adobe Illustrator or exported by another graphics application, such as Adobe Photoshop. AI files are saved in a vector format comprising paths connected by points." },
    { name: "tif", description: "A TIF file is an image saved in the Tagged Image File Format (TIFF), a high-quality graphics format. It is often used for storing images with many colors, typically digital photos, and includes support for layers and multiple pages." },
    { name: "dst", description: "A DST file is a drawing sheet set created by AutoCAD, a CAD design and documentation application. It contains information in XML format about an organized collection of sheets, which are select drawing layouts that have been extracted from multiple .DWG drawing files." },
    { name: "dds", description: "A DDS file is a raster image saved in the DirectDraw Surface (DDS) container format. It can store compressed and uncompressed pixel formats. DDS files are often used for texturing video game unit models but may also be used to store digital photos and Windows desktop backgrounds." },
    { name: "dwg", description: "A DWG file is a 2D or 3D drawing created with Autodesk AutoCAD, a professional CAD program. It contains vector image data and metadata that AutoCAD and other CAD applications use to load a drawing. DWG files are related to .DXF files, which are ASCII versions of DWG files." },
    { name: "raw", description: "A RAW file is an image generated by digital cameras such as Panasonic, Leica, and Casio cameras. It contains uncompressed, raw image data captured by a digital camera's CCD. Professional photographers often work with RAW files since they can more accurately edit images stored in the format." },
    { name: "webp", description: "A WEBP file is an image saved in the WebP (pronounced Weppy) raster image format developed by Google for web graphics. The WebP format reduces file size more than standard JPEG compression while maintaining similar or better image quality." },
    { name: "cdr", description: "A CDR file is a vector drawing or document created with CorelDRAW, a vector illustration and page layout program included with CorelDRAW Graphics Suite. It contains information that makes up a document or drawing, including text, tables, lines, shapes, images, colors, and effects properties." },
    { name: "heic ", description: "An HEIC file contains one or more images saved in the High Efficiency Image Format (HEIF), a file format most commonly used to store photos on iOS devices." },
    { name: "dfont", description: "A DFONT file is a font used by Mac OS X (now macOS). It is a TrueType font saved in an OS X-specific format. In the past, some OS X system fonts were saved as DFONT files." },
    { name: "ps", description: "A PS file is an image saved in the PostScript page description language. It contains a page layout design that may include vector graphics, raster graphics, and text." },
    { name: "nrw", description: "An NRW file is a camera raw image produced by high-end Nikon COOLPIX digital cameras. It stores image data in an uncompressed Nikon Raw (NRW) format that a camera or computer may process." },
    { name: "plt", description: "Vector-based plotter file created by AutoCAD drawing software; can be printed using a plotter, which prints images using lines instead of dots; based on the .HPGL format." },
    { name: "jfif", description: "A JFIF file is a bitmap graphic that uses JPEG compression. It is saved using a variation of the common .JPEG file format, designed to include a minimal amount of data and allow easy exchange across multiple platforms and applications." },
    { name: "heif", description: "An HEIF file is an image saved in the High Efficiency Image Format (HEIF), a lossy compressed format. It may contain still images, image collections, image bursts, and image sequences. HEIF files also store metadata that describes each image." },
    { name: "apng", description: "Unofficial extension to the Animated Portable Network Graphic (APNG) format; contains multiple frames (.PNG images) used for an animation sequence; similar to an animated .GIF file, but also supports 24-bit images and 8-bit transparency." },
    { name: "avif", description: "An AVIF file is an image saved in the AV1 Image File Format developed by the Alliance for Open Media. It contains compressed image data, similar to a .JPEG or .PNG file. The AV1 Image format was designed as an alternative to the JPEG, PNG, and .WEBP formats." },
    { name: "cad", description: "A CAD file is a CAD design created by version 21 and earlier of BobCAD-CAM software (replaced by the .BBCD format in version 22). It stores information about a 2D and 3D part, including its geometry and dimensions." },
    { name: "psp", description: "A PSP file is a bitmap image saved in the native Paint Shop Pro (PSP) format. It may include layers, guides, metadata, and other objects within the image. Corel replaced the PSP format with the .PSPIMAGE format when it released version 8 of Paint Shop Pro (now PaintShop Pro)." },
    { name: "pat", description: "CAD file used by AutoCAD, a professional CAD drawing application; stores a hatch pattern which can be used to texturize or fill an area; helps give a material appearance (e.g., brick, aluminum, glass, or plastic) to drawn objects." },
    { name: "pic", description: "Bitmap image created by IBM Lotus or a variety of other applications; possible programs include Advanced Art Studio, Micrografx Draw, and SoftImage 3D; should not be confused with the .PICT format." },
    { name: "cam", description: "A CAM file contains a machine drilling job used by Gerber Computer-Aided Manufacturing (CAM) processing software. It stores a compiled set of Gerber files saved by CAM350 software. CAM files may be used to view fabrication layers for printed circuit boards." },
    { name: "vss", description: "The .vss file format is primarily associated with Microsoft Visio, a popular diagramming and vector graphics application. VSS stands for Visio Stencil and refers to a file that contains a collection of reusable shapes, icons, symbols, and other graphical elements used in creating diagrams and visual representations." },
    { name: "srf", description: "The .srf file format is commonly associated with Sony RAW Image files. SRF stands for Sony Raw Format, and it is the proprietary raw image file format used by Sony digital cameras." },
    { name: "dps", description: "File used by DivX Player version 2, a video player program; contains skin image files in a compressed .ZIP file format; includes an .XML file named skin.divl that describes the skin images for buttons, logo, main view, compact view, font, resize policy, playback area; used to customize the look and feel of the DivX Player." },
    { name: "mix", description: "A MIX file is a game data file used by the Command & Conquer (C&C) real-time strategy games. It may contain game scenarios, movies, music, and sounds. Examples of MIX files installed with C&C games include conquer.mix, local.mix, movies.mix, and music.mix." },
    { name: "pal", description: "Settings file created by Corel Painter, a digital art application; saves the layout of workspace palettes, such as the Mixer, Layers, Color, and Navigator palettes; allows artists to customize screen layouts for different painting needs." },
    { name: "muse", description: "Website project file created by Adobe Muse, an easy-to-use Web development software that doesn't require knowledge of source code; stores a collection of pages ordered visually as a sitemap in the software; supports standard HTML elements as well as master page templates, freeform design elements, and many types of rich interactive content." },
    { name: "vsd", description: "A VSD file is a drawing project saved by Microsoft Visio, a program used to create technical drawings. It may contain visual objects, information flows, text, and other data. VSD files may also store connection information for imported data sources." },
    { name: "jpe", description: "24-bit compressed JPEG graphic format that is commonly used for Web graphics; best suited for photos or images that contain lots of colors; also used by most digital cameras for saving digital photos." },
    { name: "jp2", description: "A JP2 file is a compressed bitmap image saved in the JPEG 2000 (JP2) Core Coding format. It supports any color bit depth and image metadata and may be compressed with lossy or lossless compression. Users often save digital photos and medical images, such as CT and MRI scans, as JP2 files." },
    { name: "rgb", description: "An RGB file is a color bitmap image, saved in a file format created by Silicon Graphics (SGI). It contains binary data used to show an image using the RGB color model. RGB files are similar to .SGI files." },
    { name: "mjpeg ", description: "An MPEG file is a video file saved in a digital video format standardized by the Moving Picture Experts Group (MPEG). It contains video and audio data compressed with MPEG-1 or MPEG-2 compression and is often used to share videos over the Internet." },
    { name: "av1", description: "An AV1 file is a video encoded with AV1 compression, which is an open-source video codec developed by the Alliance for Open Media. AV1 videos are more commonly saved with the .MP4, .MKV, and .WEBM extension(s)." },
    { name: "map", description: "A MAP file is a game map created for games developed using the Quake engine or a related game development engine, such as the Valve Goldsource Engine. It is saved in a human-readable plain text format. MAP files are uncompiled but can be compiled into binary .BSP files for use in games." },
    { name: "jif", description: "A JIF file is a raster graphic saved in the JPEG Interchange Format (JIF). It typically stores digital photos and web graphics, much like .JPG and .JPEG image files. JIF files are compressed with lossy Joint Photographic Experts Group (JPEG) compression to reduce the size of the image." },
    { name: "shn", description: "Audio file compressed using Shorten lossless compression; compresses CD-quality audio without losing any quality; similar to FLAC (.FLAC) and Monkey's Audio (.APE) compression." },
    { name: "tta", description: "A TTA file is an audio file that has been compressed using the True Audio (TTA) codec. It contains audio that has been losslessly compressed to between 30 to 70 percent of its original file size. Many media players require the installation of a plug-in or codec pack to play TTA files." },
    { name: "ace", description: "File archive compressed by WinAce; uses a proprietary compression algorithm that offers a high compression ratio and fast compression speed." },
    { name: "afm", description: "ASCII text-based font format developed by Adobe; stores font metric data for a Type 1 PostScript file; contains the master design of a specific font, which defines the way each character of the font looks." },
    { name: "wve", description: "A WVE file is a video project created by the Windows version of Wondershare Filmora, a program used for making personal videos. It supports audio and video tracks as well as text, effects, transitions, and sound effects. WVE files allow projects to be saved and resumed at a later time." },
    { name: "cff", description: "CAD file format used for storing 3D packaging and die cutting designs; may be processed by either a compatible CAD software program or a CAM (computer-aided manufacturing) device." },
    { name: "wmz", description: "A WMZ file is a pre-installed or custom skin that changes the appearance of the Windows Media Player interface, often to match a certain theme. It contains a combination of graphics and JScript code that defines the look and behavior of each skin element. WMZ files are compressed using ZIP compression." },
    { name: "xa", description: "Audio file used by The Sims, a game where players build and simulate cities; contains music or other sounds for the game; commonly stored within The Sims archive files (.FAR files)." },
    { name: "dss", description: "A DSS file is a configuration backup created by DiskStation Manager (DSM), a web-based operating system included with Synology network-attached storage (NAS) devices. It contains settings for a system, which includes users, groups, shared folders, workgroup, domain, passwords, and file sharing methods." },
    { name: "sd2", description: "Audio file created in the Sound Designer II format, which was originally developed by Digidesign for Macintosh audio recording programs; can be both mono or stereo and supports various bit depths and sample rates; used for exchanging audio data between applications." },
    { name: "html", description: "An HTML file contains Hypertext Markup Language (HTML) that formats the structure of a webpage. It is stored in a standard text format and contains tags that define the page layout and content of the webpage, including the text, tables, images, and hyperlinks displayed on the webpage. HTML files are widely used online and displayed in web browsers." },
    { name: "pdf", description: "A PDF file is a multi-platform document created by Adobe Acrobat or another PDF application. The PDF format is commonly used for saving documents and publications in a standard format that can be viewed on multiple platforms." },
    { name: "shtml", description: "An SHTML file is a webpage written in HTML that contains server-side includes. It is processed (or parsed) by a web server before being sent to a user's browser. SHTML files can reference other files on the server, e.g. header.shtml or _footer.shtml." },
    { name: "doc", description: "A DOC file is a word processing document created by Microsoft Word or exported by another word processing program, such as OpenOffice Writer or Apple Pages. It may contain formatted text, images, tables, graphs, charts, page formatting, and print settings." },
    { name: "docx", description: "A DOCX file is a document created by Microsoft Word or exported by another word processing program, such as OpenOffice Writer or Apple Pages. It contains formatted text and may also include images, tables, charts, and other document elements." },
    { name: "xls", description: "An XLS file is a spreadsheet file created by Microsoft Excel or exported by another spreadsheet program, such as OpenOffice Calc or Apple Numbers. It contains one or more worksheets that store data in a table format and may include mathematical functions, charts, styles, and formatting." },
    { name: "xlsx", description: "An XLSX file is an Excel spreadsheet created by Microsoft Excel or exported by another spreadsheet program, such as OpenOffice Calc or Apple Numbers. It stores data in worksheets, which contain cells arranged in a grid of rows and columns, and may also contain charts, mathematical functions, styles, and formatting." },
    { name: "pptx", description: "A PPTX file is a presentation file created by Microsoft PowerPoint or exported by another presentation program, such as OpenOffice Impress or Apple Keynote. It contains a collection of slides, including their text, images, formatting, animations, drawn objects, and other media." },
    { name: "odt", description: "An ODT file is an OpenDocument text document, created by LibreOffice Writer, OpenOffice Writer, or another word processor. You can open an ODT file with Microsoft Word, LibreOffice Writer, or Google Docs. Those programs can convert ODT files to DOCX, DOC, PDF, and other document formats." },
    { name: "ott", description: "An OTT file is a document template saved in OASIS' OpenDocument standard format and created by word processors included in the OpenOffice and LibreOffice suites. It contains the page layout and default styles for a text document." },
    { name: "sxw", description: "Word processing document originally created by StarOffice Writer, the word processor included with StarOffice suite; based on the OpenDocument XML format and is compatible with Apache OpenOffice (formerly OpenOffice.org) Writer, the successor to StarOffice Writer." },
    { name: "stw", description: "Document template originally created by StarOffice, the predecessor to Apache OpenOffice; uses an older software file format before Apache OpenOffice adopted the newer .ODT OpenDocument format; used for writing .SDW documents from the same template." },
    { name: "docm", description: "A DOCM file is a document created by Microsoft Word, a popular word processor included in Microsoft 365. It contains embedded macros that can be executed within the document. DOCM files are used to automate repetitive or complex Word doc (.DOCX) tasks using macro functionality." },
    { name: "xml", description: "An XML file is an XML (Extensible Markup Language) data file. It contains a formatted dataset that is intended to be processed by a website, web application, or software program. XML files can be thought of as text-based databases." },
    { name: "wps", description: "A WPS file is a text document created by Microsoft Works Word Processor, the word processing program that preceded Microsoft Word. It is very similar to a Word document (.DOC file)." },
    { name: "dot", description: "A DOT file is a template created by Microsoft Word, a word processing application and may be opened by various word processors. It contains pre-formatted document settings, such as headers, footers, margins, font sizes, and other common page styles." },
    { name: "hwp", description: "An HWP file is a document created by Hancom Hanword, a Korean word processing application. HWP files typically contain text written using Hangul, the Korean alphabet. They may also contain graphics and document formatting information." },
    { name: "rtf", description: "An RTF file is a text document that supports font styling known as rich text. Examples include bold, italicized, and underlined text, as well as different typefaces and font sizes. Rich text files also support page formatting options, such as custom line spacing, tab width, and document margins." },
    { name: "txt", description: "A TXT file is a standard text document that contains plain text. It can be opened and edited in any text-editing or word-processing program. TXT files are most often created by Microsoft Notepad and Apple TextEdit, which are basic text editors that come bundled with Windows and macOS, respectively." },
    { name: "wpd", description: "A WPD file is a text document created by Corel WordPerfect, a word processor for Windows included with the WordPerfect Office suite. It may contain formatted text, tables, charts, drawn objects, and images." },
    { name: "dotm", description: "A DOTM file is a document template created by Microsoft Word, a word processing program. It contains the default layout, settings, and macros for a document. DOTM files are used to create a new .DOCM document with embedded macros." },
    { name: "pages", description: " PAGES file is a document created by Apple Pages, a word processor and page layout program for macOS and iOS. It saves a document, such as a report, poster, resume, newsletter, book, certificate, or brochure that was created from a blank page or built from a template." },
    { name: "lrf", description: "Game replay created by LOLReplay, a game replay recorder and manager for League of Legends (LoL); used for re-watching gameplay and sharing the replay with others online; can only be opened by LOLReplay (not by the League of Legends game), but still requires that League of Legends be installed on the computer." },
    { name: "sdw", description: "GIS file created for MrSID .SID raster images; contains georeferencing information that maps the raster image data in the SID file to real-world coordinates; contains the units of measurement for the image pixels as well as the coordinates of the upper-left hand pixel." },
    { name: "ods", description: "An ODS file is a spreadsheet created by Calc, a spreadsheet program included with the LibreOffice and Apache OpenOffice suites. It stores data in cells organized into rows and columns, similar to .XLSX spreadsheets, and it may also contain charts, mathematical functions, and formatting." },
    { name: "ots", description: "An OTS file is a spreadsheet template created by the Calc program included in Apache OpenOffice (formerly OpenOffice.org). It contains a spreadsheet saved in OASIS' OpenDocument XML-based formatting." },
    { name: "sxc", description: "Spreadsheet created by StarOffice Calc; supports formulas, functions, charts, and macros; also includes DataPilot tables, which can automatically extract and summarize data from raw data sets." },
    { name: "stc", description: "Connection file created by Contribute, an Adobe program used for website development and publishing; saves the remote host, the connection protocol (e.g., FTP, SFTP, etc.) and the username; enables users to reuse connection information and distribute it among website developers." },
    { name: "xlt", description: "An XLT file is a template created by Microsoft Excel, a spreadsheet application included in the Microsoft Office suite. It contains default formatting and data for a spreadsheet and is used to create new .XLS files." },
    { name: "csv", description: "A CSV file is a comma-separated values file commonly used by spreadsheet programs such as Microsoft Excel or OpenOffice Calc. It contains plain text data sets separated by commas, with each new line in the CSV file representing a new database row and each database row consisting of one or more fields separated by a comma." },
    { name: "wk1", description: "Spreadsheet created by Lotus 1-2-3, a popular spreadsheet program in the 1990s; contains cells, which are organized by rows and columns; used to store and analyze structured data, as well as quickly compute formulas." },
    { name: "wks", description: "A WKS file is a spreadsheet created by Lotus 1-2-3, a spreadsheet application developed by IBM. It stores data in a grid of cells, which may contain formulas, alphanumeric text, or number values organized by rows and columns." },
    { name: "sdc", description: "Compressed and encrypted archive used for installing MSDNAA (Microsoft Developer Network Academic Alliance) software; often distributed through ELMS (the E-academy License Management System), a system for distributing software packages to academic institutions." },
    { name: "dbf", description: "A DBF file is a standard database file used by dBASE, a database management system application. It organizes data into multiple records with fields stored in an array data type. DBF files are also compatible with other xBase database programs, which arose because of the file format's popularity." },
    { name: "odp", description: "An ODP file is a presentation created by Impress, a presentation program included with the LibreOffice and Apache OpenOffice suites. It contains a series of slides that may contain text, images, media, and transition effects. ODP files are similar to .PPTX files." },
    { name: "odg", description: "An ODG file is a drawing created by Draw, a vector graphics editing application available in the LibreOffice and Apache OpenOffice suites. It contains one or more pages that may include lines, shapes, symbols, and text." },
    { name: "otp", description: "An OTP file is a presentation template created by Impress, a presentation program included with Apache OpenOffice (formerly known as OpenOffice.org). It contains a presentation, which includes a master slide as well as a content slide with text, images, shapes, multimedia content, transition effects, and other slide elements." },
    { name: "sxi", description: "Presentation originally created by StarOffice Impress, a program used for creating slide shows; generally follows a slide layout template and contains a title slide followed by content slides; supports audio, video, and transition effects." },
    { name: "sti", description: "Presentation template originally created by StarOffice, the predecessor to Apache OpenOffice; used for creating presentations with a similar design; often includes a pre-formatted header and footer for the title slide and content slides." },
    { name: "pps", description: "A PPS file is a slide show created by Microsoft PowerPoint, a popular program included with Microsoft Office for creating presentations. It is saved as a completed slide show, which can be viewed by PowerPoint, OpenOffice, and several other presentation programs." },
    { name: "pot", description: "A POT file is a template created by PowerPoint, a program used to create slide show presentations. It contains the default layout, formatting, and styles for a slide show. POT files are used to create multiple .PPT files with the same formatting." },
    { name: "sda", description: "Drawing or diagram created by Apache OpenOffice Draw, a vector graphics drawing program included with Apache OpenOffice and Oracle's Open Office; typically contains drawn vector objects but also supports raster graphics." },
    { name: "sdd", description: "Presentation originally created by StarImpress, a slide show program included in the StarOffice suite; saves a collection of slides, often incorporating a title slide followed by supporting slides." },
    { name: "sdp", description: "Informational file created with media broadcasting software; contains the format, timing, and authorship of the streamed media; may be created for live streaming media with a program like Sorenson Broadcaster or for prerecorded media using a program like PlaylistBroadcaster." },
    { name: "pptm", description: "A PPTM file is a macro-enabled presentation created by Microsoft PowerPoint, an application used to create presentations and slide shows. It contains a collection of slides, which typically includes text, layout, images, and embedded macros." },
    { name: "ppsx", description: "A PPSX file is a slide show created by Microsoft PowerPoint or another presentation program, such as OpenOffice Impress or SoftOffice Presentations. It contains a finished collection of slides that are used for slide show playback. PPSX files are similar to .PPTX files but open as a presentation slide show when double-clicked, instead of a working presentation file." },
    { name: "ppt", description: "A PPT file is an editable slide show created by Microsoft PowerPoint or exported by another presentation program, such as OpenOffice Impress or Apple Keynote. It may include formatted text, bullet points, images, movies, sound effects, and music." },
    { name: "dotx", description: "A DOTX file is a document template created by Microsoft Word, a word processing program. It contains a default layout and settings for a document, including styles, AutoText, toolbars, and macros. DOTX files are used as a baseline to create multiple .DOCX documents with the same formatting." },
    { name: "pdb", description: "A PDB file is a database file used by various applications, including Pegasus, Quicken, Microsoft Visual Studio, and Palm Pilot software. It stores data in a structured format and is typically installed with the corresponding application." },
    { name: "xps", description: "An XPS file is a document that contains fixed page layout information written in the XPS page description language. The file defines a document's layout, appearance, and printing information. XPS files are similar to .PDF files, but saved in Microsoft’s proprietary XPS format." },
    { name: "oxps", description: "An OXPS file is a document saved in the Open XML Paper Specification (OpenXPS) format. It stores information that describes the structure and content of the document and preserves it as a fixed layout similar to .PDF documents." },
    { name: "cbr", description: "A CBR file is a compressed set of .JPEG, .PNG, or static .GIF images saved in a single .RAR archive with the .cbr extension. It contains pages that are automatically ordered and can be presented one or two at a time. CBR files are used for storing and displaying comic books or other collections of pages." },
    { name: "cbz", description: "A CBZ file is a comic book archive saved in the Comic Book Zip (CBZ) format, which is a .ZIP file with a .cbz file extension. It contains multiple images, commonly .PNG or .JPEG files, typically organized into folders that correlate with the pages and chapters of the comic." },
    { name: "htm", description: "An HTM file is a webpage written using Hypertext Markup Language (HTML). It contains tags and text that define the webpage's layout and content. HTM files are the same as .HTML files." },
    { name: "key", description: "A KEY file contains a license key an application references to register itself to a computer on which it is installed. It may save the key in a plain text format but typically stores an encrypted key string that authenticates the purchase and registers the software." },
    { name: "numbers", description: "A NUMBERS file is a spreadsheet created by Apple Numbers, a spreadsheet program bundled with macOS. It contains one or more sheets with data organized into rows and columns, and may also include tables, graphs, images, formulas, and other types of data." },
    { name: "pub", description: "A PUB file is a document created by Microsoft Publisher, a desktop publishing program used to create professional documents and marketing materials. It contains a page layout and design elements, such as text, shapes, and vector or raster graphics." },
    { name: "woff", description: "A WOFF file is a web font file created in the WOFF (Web Open Font Format) format, an open format used for delivering webpage fonts on the fly. It is saved as a compressed container and supports TrueType (.TTF) and OpenType (.OTF) fonts and also supports font licensing information." },
    { name: "msg", description: "An MSG file is an item, such as an email message, contact, appointment, or task created or saved within Microsoft Outlook. It contains all the information about the item, which includes attachments to an email message." },
    { name: "eml", description: "An EML file is an email message saved by an email application, such as Microsoft Outlook or Apple Mail. It contains the content of the message, along with the subject, sender, recipient(s), and date of the message." },
    { name: "otf", description: "An OTF file is a font saved in the OpenType format developed by Microsoft and Adobe. The OpenType format combines aspects of the PostScript (.PS) and TrueType (.TTF) font formats. OTF fonts are fully scalable, meaning their characters can be resized without losing quality." },
    { name: "word", description: "The Word format is a proprietary file format created by Microsoft Word, which is a word processing program included in the Microsoft Office suite. The Word format files have a .doc or .docx file extension and can contain a variety of elements such as text, images, tables, charts, and other objects." },
    { name: "bpm", description: "File created by Bizagi Process Modeler, a business process modeling and documentation application; contains processes that consist of diagram shapes, images, arrow connectors, etc.; referred to as a model and may contain one or more diagrams." },
    { name: "fig", description: "A FIG file contains a figure created by The MathWorks MATLAB, an application for mathematical computations and data analysis. It stores one or more plots or graphs, which MATLAB uses to visualize data." },
    { name: "one", description: "A ONE file is a notebook created by Microsoft OneNote, a note-taking program. It contains one or more pages of notes, which are organized into sections. ONE files may contain text, digitized handwriting, and objects pasted from other applications, such as images, drawings, and audio or video clips." },
    { name: "jnt", description: "Journal created with Windows Journal note-taking software; typically created on a Tablet PC, but may also be created on a standard Windows PC with Windows Journal installed." },
    { name: "exp", description: "An EXP file is a symbols export file, produced by an integrated development environment (IDE) or compiler. It contains binary information about exported data and functions." },
    { name: "css", description: "A CSS file is a cascading style sheet (CSS) file used to format the contents of a webpage. It contains customized global properties for how to display HTML elements. For example, CSS files can define the size, color, font, line spacing, indentation, borders, and location of HTML elements." },
    { name: "cfm", description: "A CFM file is a webpage that contains ColdFusion code, or CFML (ColdFusion Markup Language). It is dynamically executed by a ColdFusion web server when the page is accessed by a user. CFM files can run ColdFusion applications and scripts written in CFScript." },
    { name: "osm", description: "An OSM file is a street map saved in the OpenStreetMap (OSM) format. It contains XML-formatted data in the form of nodes (points), ways (connections), and relations (street and object properties, such as tags)." },
    { name: "asd", description: "An ASD file is a temporary backup created by Microsoft Word, a word processing program used to author documents. It contains a snapshot copy of the currently opened Word document." },
    { name: "contact", description: "Contact file created by Windows Contacts, a built-in contact management program included with Windows Vista and Windows 7; contains information about a person or organization, including name, picture, email address, phone number etc." },
    { name: "cal", description: "Calendar created by various Broderbund calendar design programs; contains dates and events, separated by months; may also include custom images for each month and user-defined formatting." },
    { name: "spc", description: "Audio file extracted from SNES (Super Nintendo Entertainment System) video games; contains a 16-bit, 32KHz soundtrack; often extracted from an SNES ROM using an SNES emulator." },
    { name: "mac", description: "Bitmap graphic created by MacPaint for the Macintosh, one of the first painting programs for the Mac; supports only black-and-white graphics since the original Macintosh had a monochrome monitor." },
    { name: "sec", description: "Contains ASCII armored private keys used for decrypting PGP (Pretty Good Privacy) encrypted files." },
    { name: "tax", description: "Tax return file created by TurboTax, a U.S. federal and state tax preparation program; saves data for filling the electronic forms required to file individual or small business taxes." },
    { name: "site", description: "A SITE file is a website project file created in Adobe GoLive. It contains a list of webpages, images, links, errors, site settings, and other information used to show a website in GoLive. SITE files include references to a website's component files, but they do not contain the files themselves." },
    { name: "style", description: "Sketch style that can be applied to a drawing created with Google SketchUp, a 3D modeling program; examples include wavy pencil lines, graphite stick marks, or quick pen strokes; often used to make a CAD model look hand-drawn." },
    { name: "rtl", description: "Printer and plotter file created in Hewlett-Packard's Raster Transfer Language (HP-RTL); used to instruct the plotter on the layout of image objects such as scanned photographs and help shade areas." },
    { name: "olm", description: "Database file used by Microsoft Outlook for Mac; used as the software's internal format for storing email messages, contacts, journals, calendar data, and other user application data." },
    { name: "max", description: "A MAX file is a three-dimensional scene created by Autodesk 3ds Max, a 3D modeling, animation, and rendering application. It contains one or more 3D models, including wireframes, textures, lighting effects, shading, animations, and other 3D design elements." },
    { name: "ind", description: "An IND file is a file placed on a Memory Stick media card when formatted with a Sony device. It is created by various Sony devices when formatting a Memory Stick to indicate that the type of media connected to the device is a Memory Stick. IND files are typically named MEMSTICK.IND." },
    { name: "epf", description: "An EPF file is a project created by EAGLE, a Printed Circuit Boards (PCB) designing program. It contains project information such as version and platform. EPF files include references to boards (.BRD file) and schematics (.SCH file) that are associated with the project." },
    { name: "act", description: "An ACT file is an audio file recorded by a Chinese MP3 player, MP4 player, or voice recorder. It typically contains a voice memo or a song recorded off the radio. ACT files are compressed using Adaptive Differential Pulse Code Modulation (ADPCM) and have a bitrate of 8 kbit/s." },
    { name: "fit", description: "A FIT file is a GIS data file used by Garmin GPS sport devices and Garmin software. It contains location and time information logged on the GPS device during a run, bike race, or other athletic event." },
    { name: "sla", description: "Document created by Scribus, a free open source desktop publishing application; saved using XML formatting and contains document text, images, and layout settings; used for saving written work." },
    { name: "sat", description: "A SAT file is a 3D model saved in Spatial's ACIS solid modeling format. It stores three-dimensional geometry information in a standard text file format. SAT files are supported by many 3D CAD programs, so they are commonly used to exchange 3D data across programs." },
    { name: "bms", description: "A BMS file is a plain text script used by QuickBMS, a file extraction tool used primarily to extract assets from video game asset archives. It contains directives written in the Binary MultiEx Script (BMS) language." },
    { name: "cnc", description: "A CNC file is a path file created by QuickCAM Pro, a program used to create cutter paths for machining 3D parts on a milling machine. It contains path instructions for cutting a 3D part using a CNC milling machine." },
    { name: "sam", description: "Sample of the LMHOSTS setting file used by Microsoft Windows; contains mappings of IP addresses to NetBIOS names of remote servers a computer will communicate with over TCP/IP." },
    { name: "dex", description: " DEX file is an executable file saved in a format that contains compiled code written for Android, Google's Linux-based mobile phone platform. It is technically referred to as a Dalvik Executable, and can be interpreted by the Dalvik virtual machine." },
    { name: "pez", description: "Presentation created by Prezi Desktop, a program used for making zooming presentations, which feature a single canvas that can be panned, rotated, and zoomed to view different notes and pictures on the canvas." },
    { name: "ipynb", description: "An IPYNB file is a notebook document created by Jupyter Notebook, an interactive computational environment that helps scientists manipulate and analyze data using Python. It contains all the content from a Jupyter Notebook web application session, including computation inputs and outputs, mathematical functions, images, and explanatory text." },
    { name: "aspx", description: "An ASPX file is a webpage written in the Active Server Page Extended (ASPX) format. Web servers running Microsoft's ASP.NET framework use ASPX files to generate dynamic webpages." },
    { name: "dat", description: "A DAT file is a generic data file created by a specific application. It may contain data in binary or text format. DAT files are typically accessed only by the application that created them." },
    { name: "dwf", description: "A DWF file is a 2D or 3D drawing saved in the Design Web Format (DWF) developed by Autodesk. It contains design data, which includes graphics and text. DWF files are saved in a Zip-compressed format to reduce their file size." },
    { name: "latex", description: "Document prepared by LaTeX, a high-quality typesetting system; often used for technical and scientific documents, but can be used for many other forms of publishing." },
    { name: "wv", description: "A WV file is an audio file compressed using WavPack Hybrid Lossless Compression. WavPack supports both lossy and lossless compression, which produces high-quality compressed files. WV files' compression ratio is typically between 30% and 70%." },
    { name: "rb", description: "An RB file is a software program written in Ruby, an object-oriented scripting language. Ruby is designed to be simple, efficient, and easy to read. RB files can be edited with a text editor and run using Ruby." },
    { name: "w64", description: "A W64 file is an audio file saved in Sony's Wave64 format, which is supported by various Sony audio editing programs. The W64 format is useful for audio editing because it supports arbitrary sampling frequencies, channels, and bit depths." },
    { name: "ac3", description: "An AC3 file is an audio file saved in the AC-3 (Dolby Digital) audio format. It can include up to six channels of audio (DD 5.1 surround sound with subwoofer output). Cinema movies, DVDs, Blu-rays, and video games often use audio saved as AC3 files." },
    { name: "ra ", description: "Audio file used by RealPlayer, an audio and video streaming program; may reference a streaming audio file online or may contain audio data; can use various codecs to compress audio with variable bit rates; uses a proprietary Real compression algorithm." },
    { name: "d", description: "Source code written in the D programming language; similar to C++, but also influenced by C#, Java, and Eiffel; can be opened and edited in a text editor, but requires a D compiler to be compiled into an executable program." },
    { name: "jps", description: "A JPS file is a stereoscopic JPEG image used for creating 3D effects from 2D images. It contains two static images, one for the left eye and one for the right eye that are encoded as two side-by-side images in a single .JPG file." },
    { name: "vqf", description: "Audio format developed by the Nippon Telegraph and Telephone Corporation (NNT Labs); uses transform-domain weighted interleaved vector quantization to compress audio data; can be encoded in 80, 96, 112, 128, 160, and 192 kbps bitrates." },
    { name: "exr", description: "An EXR file is a raster image stored in the OpenEXR format, a high dynamic-range (HDR) image format developed by Academy Software Foundation (ASWF). The EXR format supports multi-layer images, lossy and lossless compression, and 16-bit and 32-bit pixels." },
    { name: "dts", description: "Multi-channel audio file encoded in the Digital Theater Systems (DTS) format; saves five tracks of audio data used for surround sound; channels include center, left-front, right-front, left-rear, and right-rear." },
    { name: "gsm", description: "3D object that is defined using the Graphic Description Language (GDL), a standard method of describing three-dimensional shapes; recognized by Archicad and other Building Information Modeling (BIM) applications." },
    { name: "cab", description: "A CAB file is a Windows Cabinet file saved in an archive format native to Microsoft Windows. The CAB format supports the .ZIP, Quantum, and LZX data compression algorithms. CAB files contain compressed data and are used for Windows software installations." },
    { name: "bin", description: "A BIN file is a generic data file that stores information entirely or partially in binary format. The .bin extension is used by various applications for multiple types of files. Examples include saved data files, disk images, resource libraries, and firmware updates." },
    { name: "jbg", description: "Image format used to store black and white, grayscale, and color images; uses lossless compression to create smaller image file sizes than other industry standard formats, such as CCITT Group 3 (G3) or CCITT Group 4 (G4) compression." },
    { name: "vox", description: "3D model created in the Voxel format (VOX for short), a blocky 3D format used by the Voxlap game engine; saves the geometry for a game object, such as a character, building, or a weapon; for used for creating video game models and can be edited with the Slab6 program." },
    { name: "xpm", description: "Bitmap image used by the X Windows System, a (GUI) used to run networked client-server computers; supports monochrome, gray-scale, and color pixmap data; stores pixmaps as static character arrays in the C programming language." },
    { name: "yuv", description: "A YUV file is a video file encoded in the YUV format. It stores a sequence of YUV images as a single video file and may be saved in YUV 4:2:0, 4:2:2, 4:4:4 formats. YUV video files may be decoded by MPEG-4 and H.264 decoders." },
    { name: "abw", description: "An ABW file is a word-processing document created by AbiSource AbiWord, a free word processor developed by the AbiSource community. It contains formatted text and supports advanced document layouts, images, tables, lists, and footnotes." },
    { name: "potx", description: "A POTX file is a presentation template created by PowerPoint, a program that allows users to create slide show presentations. It contains default styles, text, layout, and formatting. POTX files are used to create multiple .PPTX files with the same formatting." },
    { name: "ape", description: "An APE file is a component referenced by the Advanced Visualization Studio (AVS) plugin for the Nullsoft Winamp media player. It stores effect information referenced by the AVS plug to configure visual animations displayed when playing music with Winamp." },
    { name: "deb", description: "A DEB file is a software package used by the Debian Linux distribution and its variants, such as Ubuntu. DEB files are used primarily to install or update Unix applications. Each DEB file is a standard Unix archive that contains two .TAR archives: one for installer control information and another for installable data." },
    { name: "sln", description: "An SLN file is a structure file used for organizing projects in Microsoft Visual Studio. It contains text-based information about the project environment and project state." },
    { name: "sr2", description: "An SR2 file is a raw camera image created by Sony digital cameras such as the R-1. It stores uncompressed image data in the Sony RAW 2 (SR2) format exactly how the camera's sensor CCD captured it." },
    { name: "avr", description: "Audio file format created by Audio Visual Research for the older Atari ST computer systems; can be 8 or 16-bit and contain 1 or 2 channels; not commonly used anymore." },
    { name: "voc", description: "Audio file format used by Creative Labs hardware, such as SoundBlaster sound cards; may be audio clips, sound effects, or instrument sounds, which are also referred to as voices; VOC files are compressed to reduce file size." },
    { name: "smp", description: "An SMP file is an encrypted .MP3 file that can be found on a memory card used by a Salvat AudioCuentos or Märchenheld Sammlung audio player. It contains a children's audiobook. SMP files can be played only by AudioCuentos and Märchenheld audio players." },
    { name: "rpm", description: "A file with .rpm extension is a Red Hat Linux operating system package for installations of programs on Linux systems. The Red Hat Package Manager uses the RPM file format and is free and open-source package management system." },
    { name: "djvu", description: "A DJVU file is a scanned document saved in the DjVu image format. It may contain text, images, and/or drawings. The DjVu format was developed by AT&T and marketed by LizardTech." },
    { name: "fb2", description: "An FB2 file is a FictionBook 2.0 file saved in an eBook format developed in Russia. It contains XML that describes each element of the book and is designed primarily for fictional literature." },
    { name: "epub", description: "An EPUB file is a digital book (eBook) saved in the open Electronic Publication (EPUB) format, a standard digital publication format that publishers and consumers use to distribute eBooks." },
    { name: "mobi", description: "A MOBI file is a digital book (eBook) saved in the proprietary MOBI format. It contains binary data that makes up an eBook (a book, magazine, scientific publication, etc.) and may incorporate DRM copyright protection to prevent copying or unauthorized viewing." },
    { name: "azw3", description: "An AZW3 file is an eBook created in the Kindle Format 8 (KF8), Amazon's successor to the .AZW format. It supports newer features such as HTML5 and CSS3 support as well as many other formatting capabilities." },
    { name: "lit", description: "Electronic version of a book or other publication saved in the Microsoft LIT format; includes digital rights management (DRM) information, which protects copyrights by limiting the systems each book can be read on." },
    { name: "snb", description: "An SNB file is a note created by S Note, an advanced note-taking application developed by Samsung for use with Samsung mobile devices, such as Galaxy S and Galaxy Note. It contains a note, which may include text, video, audio, sketches, and images." },
    { name: "tcr", description: "eBook created in the TCR format, which was originally used by the older Psion Series 3 palmtop devices developed in the 1990s; stores the eBook text and formatting in a proprietary format used internally by the Psion device." },
    { name: "azw", description: "An AZW file is a digital eBook formatted for the Amazon Kindle, a wireless reading device. It contains formatted book text and chapter markers and is similar to a Mobipocket .PRC file." },
    { name: "prc", description: "A PRC file is a 3D model saved in the Product Representation Compact (PRC) format. It contains a 3D representation of a mechanical product or assembly. Previous versions of Adobe Acrobat allowed users to convert many 3D model file types to the PRC format, so the models could be embedded in .PDF files." },
    { name: "tar.bz2", description: "TAR archive compressed with the BZ2 compression algorithm; contains one or more compressed files; commonly used for Linux software package distributions." },
    { name: "tar.gz", description: "A TAR.GZ file is a TAR archive compressed with the standard GNU zip (gzip) compression algorithm. It contains one or more compressed files and is commonly used on Unix operating systems to package files, programs, and installers." },
    { name: "rar", description: "A RAR file is an archive that contains one or more files compressed with RAR compression. It is compressed with a higher compression ratio than typical ZIP compression and incorporates a proprietary compression algorithm utilized by other compressors, including 7-Zip for its .7Z files." },
    { name: "tar", description: "A TAR file is an archive saved in the Tape Archive format. It contains multiple files that have been packaged together for easier storage and sharing. Notably, unlike .ZIP files and many other types of archives, TAR files are not compressed." },
    { name: "tbz2", description: "A TBZ2 file is a .TAR archive that has been compressed using bzip2 compression. Often, TBZ2 files contain Linux programs or program installers. To extract the files a TBZ2 file contains, users need both a bzip2 decompression utility and a tar expansion utility." },
    { name: "tgz", description: "A TGZ file is a Unix .TAR archive compressed with Gnu Zip (.GZIP) compression to reduce the file size. It is commonly used on Unix and Linux systems and was originally created as the shorthand version of the compound extension .TAR.GZ." },
    { name: "zip", description: "A ZIP file is an archive that contains one or more files compressed or zipped with Zip compression. It stores files separately from each other, allowing the files to be compressed using different methods and extracted without compressing or decompressing the entire archive." },
    { name: "7z", description: "A 7Z file is a compressed archive created by various file compression utilities, most notably, Igor Pavlov's 7-Zip. It is compressed with open-source LZMA compression, which features a high compression ratio and may include AES-256 (256-bit) encryption." },
    { name: "xz", description: "An XZ file is an archive compressed using XZ compression, a high-ratio compression algorithm based on the LZMA algorithm. It contains one or more files compressed by the xz command-line tool included with XZ Utils." },
    { name: "iso", description: "An ISO file is a disk image that contains an exact duplicate of data from a source disk, such as an optical disc or hard drive. Computer users typically use ISO files to duplicate CD, DVD, or Blu-ray discs for sharing or backup purposes or download ISO files from a website to install software on a computer." },
    { name: "arc", description: "An ARC file is a backup file created by Norton software products, such as Norton 360 and Norton Online Backup. It contains a backup of computer files created automatically by the software, which may include personal user files as well as system files." },
    { name: "mp3", description: "An MP3 file is an audio file saved in a compressed audio format developed by the Moving Picture Experts Group (MPEG) that uses Layer 3 audio compression (MP3). MP3 files are commonly used to store audio tracks, podcasts, lectures, sermons, and audiobooks." },
    { name: "wav", description: "A WAV file is an audio file saved in the WAVE format, a standard digital audio file format utilized for storing waveform data. WAV files may contain audio recordings with different sampling rates and bitrates but are often saved in a 44.1 kHz, 16-bit, stereo format, which is the standard format used for CD audio." },
    { name: "m4a", description: "An M4A file is an audio file that may store various types of audio content, such as songs, podcasts, and audiobooks. It is saved in the MPEG-4 format and encoded with either the Advanced Audio Coding (AAC) codec or the Apple Lossless Audio Codec (ALAC)." },
    { name: "wma", description: "A WMA file is an audio file saved in the Advanced Systems Format (ASF) container format developed by Microsoft. It stores audio data encoded in the Windows Media Audio (WMA), WMA Pro, WMA Lossless, or WMA Voice codecs." },
    { name: "ogg", description: "An OGG file is an audio file, similar to an .MP3 file, that typically stores music. It contains audio data saved in the Ogg container format and compressed with Vorbis audio compression. OGG files may also include song metadata, such as artist information and track data." },
    { name: "flac", description: "A FLAC file is an audio file compressed in the Free Lossless Audio Codec (FLAC) format, which is an open-source lossless audio compression format. It is similar to an .MP3 file, but is compressed without losing any quality or original audio data." },
    { name: "aac", description: "An AAC file is an audio file similar to an .MP3 file but compressed with Advanced Audio Coding (AAC), a lossy digital audio compression standard. The lossy compression maintains quality nearly indistinguishable from the original audio source, making it optimal for compressing music data." },
    { name: "aiff", description: "An AIFF file is an audio file saved in the Audio Interchange File Format (AIFF). It contains high-quality audio stored in an uncompressed lossless format. AIFF files may also be saved as .AIF files or .AIFC files (if they are compressed)." },
    { name: "opus", description: "An OPUS file is an audio file created in the Opus format, a lossy audio format developed for Internet streaming. It uses both the SILK (used by Skype) and CELT (from Xiph.Org) codecs and supports variable bit rates from 6 kb/s to 510 kb/s." },
    { name: "oga", description: "An OGA file is an audio file saved using the Ogg audio container format. It may be encoded using one of several audio codecs, including FLAC, Ghost, Opus, or OggPCM. OGA files may also be encoded using the Ogg Vorbis or Speex codecs." },
    { name: "adts", description: "Audio file created in the Audio Data Transport Stream (ADTS) format; stores a series of audio frames, each of which includes a segment of the overall audio data to be transported; often used for streaming .AAC audio on the Internet." },
    { name: "amr", description: "An AMR file is an audio file saved in a compressed format developed by Ericsson. It is optimized for storing spoken audio and is used by many 3G cell phones to store voice recordings, which include MMS messages." },
    { name: "m4r", description: "An M4R file contains a custom iPhone ringtone created with Apple iTunes, a media player bundled with Apple devices. It can only be created from a song purchased from the iTunes music store that allows ringtone creation." },
    { name: "alac", description: "An ALAC file is an audio file encoded using the Apple Lossless Audio Codec (ALAC). Most likely, it contains a song meant to be played in Apple iTunes or Apple Music. More commonly, ALAC files use the .M4A extension." },
    { name: "caf", description: "A CAF file is an audio file saved in the Core Audio Format (CAF), a digital audio format based on Apple's Core Audio technology. It stores audio data, text annotations, markers, channel layouts, and other information." },
    { name: "m4p", description: "Protected .AAC audio file downloaded from the iTunes Music Store; incorporates Apple Computer's Fairplay DRM copy protection, which limits the number of computers the file can be played on." },
    { name: "m4b", description: "An M4B file is an audiobook most often used by Apple iTunes and Apple Books. It contains audio that is stored in the MPEG-4 format and compressed using AAC encoding." },
    { name: "midi", description: "A MIDI file contains electronic music data saved in the Musical Instrument Digital Interface (MIDI) format. The MIDI format defines notes that are played, when they are played, how long they are held, and their velocities (how hard they are pressed)." },
    { name: "weba", description: "A WEBA file is an audio-only .WEBM file. It contains audio that was likely extracted from a WEBM video. WEBA files are compressed using either the Ogg Vorbis or Opus audio formats." },
    { name: "all", description: "Security policy file used by Java RMI-enabled technologies such as Apache River (formerly Jini) and GigaSpaces; stores data access permissions and allows all entities full permissions; often used for development configurations, where developers are in a secure environment." },
    { name: "band", description: "A BAND file is an audio editing project created by Apple GarageBand, a digital audio workstation (DAW) available for macOS and iOS. It is a macOS package that contains the project's imported media files, settings, project data, and other resources." },
    { name: "ram", description: "Link file used by RealPlayer, a program used for streaming Internet audio and video; saved in plain text and contains a hyperlink to a corresponding .RA file, which can be streamed; includes a pnm or rtsp URL, which are the protocols used to access and play RealAudio data." },
    { name: "als", description: "An ALS file is an audio project, or live set, file created by Ableton Live, a program used for creating electronic mixes. It saves the arrangement of clips on a multitrack timeline with mix settings and references to the clips, but not the clip files themselves." },
    { name: "sid", description: "Backup file created by Steam, an application used to download and manage Valve video games on desktop computers; contains compressed game data created by the Steam backup feature (steambackup.exe program); used to backup games to DVDs or other external media." },
    { name: "cfa ", description: "A CFA file is a data capture file created by Frontline ComProbe analyzers, such as the BPA 600 Dual Mode Bluetooth Protocol Analyzer and the BPA Low Energy Bluetooth Protocol Analyzer." },
    { name: "cpr", description: "Audio production project created by Cubase, a Digital Audio Workstation (DAW) application; stores project data such as audio and MIDI tracks, software instruments, audio effects, and mixer settings; also contains references to audio file assets." },
    { name: "abc", description: "Music file created in the ABC musical notation language, which stores the composition in a plain text ASCII format; can be translated and laid out in an actual score and also played back using an ABC-compatible application." },
    { name: "wax", description: "Audio shortcut file used by Windows Media Player, the default Windows multimedia playback program; saves a shortcut to a Windows Media audio (.WMA) file on the Internet; saved in a plain text format and contains the URL location of the audio file, but not the audio data itself." },
    { name: "spx", description: "An SPX file is an audio file saved in the Ogg Vorbis container format and encoded with the Speex Audio codec. It supports voice compression at 2 to 44 kbps and supports 8 kHz, 16 kHz, and 32 kHz compression in the same bitstream." },
    { name: "vivo", description: "Video format typically used for adult videos; VivoActive products were discontinued in 1997, but are still supported by RealPlayer." },
    { name: "mp2", description: "An MP2 file is an audio file compressed using MPEG-1 Audio Layer II compression. The .MP3 format has largely replaced the MP2 format. However, MP2 is still used for digital radio and television broadcasts." },
    { name: "tak", description: "Audio file created in the Tom's lossless Audio Kompressor (TAK) format; uses an adaptive linear forward prediction technique to reduce the size of an audio file without losing audio quality; also supports non-audio data such as CD cover images or cue sheets." },
    { name: "snd", description: "Generic sound format used by a variety of programs; may be a Macintosh sound resource (from Mac OS Classic), an AKAI MPC audio sample, a generic Amiga sound, or other audio file." },
    { name: "au", description: "An AU file is an audio file created by Audacity, a free, cross-platform audio editor. It is saved in a proprietary audio format used only by Audacity. AU files are part of Audacity projects, which are saved as .AUP files." },
    { name: "ppsm", description: "A PPSM file is a macro-enabled slide show created by Microsoft PowerPoint, a program that allows users to create slide shows and presentations. It contains one or more macro-enabled slides, which may include text, images, and transitions." },
    { name: "kdc", description: "A KDC file is a raw photo created by a Kodak digital camera. It contains image data captured by a camera's CCD sensor, saved in an uncompressed, unprocessed format. Photographers use KDC files to produce finalized, edited photos." },
    { name: "pef", description: "A PEF file is a raw camera image created by Pentax digital cameras. It stores image information exactly how it was captured by the camera's CCD sensor, with no compression or color adjustment." },
    { name: "rgba", description: "An RGBA file is a color bitmap image saved in a file format created by Silicon Graphics (SGI). It contains binary data used to show an image using the RGB color model and an alpha channel. RGBA files are similar to .RGB files, except RGB files do not include an alpha channel." },
    { name: "rw2", description: "An RW2 file is a camera raw image captured by a Panasonic LUMIX digital camera, such as the LX3 and LX5. It stores image data in a format based on the TIFF specification similar to .RAW and .RWL formats." },
    { name: "cvs", description: "Proprietary image format used by early versions of Canvas, a drawing program designed for creating technical illustrations; may contain both vector images and raster images." },
    { name: "mp4", description: "An MP4 file is a video saved in the MPEG-4 format. In addition to a video and accompanying audio, MP4 files may contain subtitles, still images, and video metadata. Because MP4 files are quite common, you can play them in most popular media players." },
    { name: "3gp", description: "A 3GP file is a multimedia file saved in an audio and video container format developed by the 3rd Generation Partnership Project (3GPP). It stores video and audio streams transmitted between 3G mobile phones and over the Internet." },
    { name: "avchd", description: "A file-based format for digital recording and playback of high-definition video; its name is an acronym which stands for Advanced Video Coding High Definition." },
    { name: "avi", description: "An AVI file is a video file saved in the Audio Video Interleave (AVI) multimedia container format created by Microsoft. It stores video and audio data encoded in various codecs, including DivX and XviD." },
    { name: "flv", description: "An FLV file is a video saved in the Adobe Flash Video (FLV) container format. It stores a short header, synchronized audio and video data streams (encoded the same way as streams in the standard Flash .SWF format), and metadata packets." },
    { name: "m2ts", description: "Video file saved in the high-definition Blu-ray BDAV format; used for saving video data on Blu-ray discs; based on the MPEG-2 transport stream and supports 720p and 1080i formats." },
    { name: "m4v", description: "An M4V file is an MPEG-4 file used to store video downloaded from the Apple iTunes store, such as TV episodes, music videos, and full-length movies. It is similar to the .MP4 file, but may be copy-protected using Apple's FairPlay DRM copyright protection." },
    { name: "mkv", description: "An MKV file is a video file saved in the Matroska multimedia container format that often stores short video clips, TV shows, and movies. It supports several types of audio and video codecs and may include .SRT, .SSA, .USF (Universal Subtitle Format), or VobSub subtitles." },
    { name: "mov", description: "A MOV file is one of the most common video file types, often storing a movie, TV show, short video clip, or home movie. It is saved in the QuickTime File Format (QTFF), a multimedia container format developed by Apple." },
    { name: "mts", description: "An MTS file is a video saved in the Advanced Video Coding High Definition (AVCHD) format. AVCHD is the standard video format used by many Sony and Panasonic HD camcorders. MTS files also store high-definition video on Blu-ray discs." },
    { name: "mxf", description: "An MXF file is a video saved in the Material Exchange file format developed by The Society of Motion Picture and Television Engineers (SMPTE). It stores video and audio data and metadata that describes that data." },
    { name: "swf", description: "An SWF file is an animation that may contain text, vector and raster graphics, and interactive content written in ActionScript. It is saved in the Adobe Flash format, which Adobe discontinued on December 31, 2020." },
    { name: "ts", description: "A TS file is a video file saved in the Video Transport Stream (TS) format, which stores video data compressed with standard MPEG-2 (.MPEG) video compression. The TS format is often used to save video on a DVD disc but may also store streamed or broadcast video." },
    { name: "vob", description: "A VOB file is a video file from a DVD, typically stored in the VIDEO_TS folder at the root of the DVD along with .BUP and .IFO files. It contains most of the data stored on the disc, including video, audio, subtitles, DVD menus, and other navigation contents. VOB files are limited to 1 GB." },
    { name: "webm", description: "A WEBM file is a video saved in the WebM format, an open, royalty-free format designed for sharing video on the web. WebM uses a container structure similar to the Matroska (.MKV) video format, which stores both audio and video data." },
    { name: "wmv", description: "A WMV file is a video saved in the Microsoft Advanced Systems Format (ASF) and compressed with Windows Media Video (WMV) compression. It may store an animation, video clip, TV episode, or movie and supports high-definition (HD) video." },
    { name: "3g2", description: "A 3G2 file is a multimedia file saved in the 3GPP2 video and audio container format developed by the 3rd Generation Partnership Project 2 (3GPP2). 3G2 files are primarily used for transmitting multimedia content over the Internet and are also used by cell phones to store videos." },
    { name: "aaf", description: "File used by Avid audio editing and production software products such as Avid Pro Tools and Avid Media Composer; contains links to audio and video files as well as editing decisions that are applied to the audio and video data." },
    { name: "asf", description: "An ASF file is a multimedia file stored in the Advanced Systems Format (ASF), a proprietary video and audio container format. It may store only audio data or video and audio data, along with optional metadata." },
    { name: "divx", description: "High-quality, high-compression video codec used for digital video distribution; compresses video to a fraction of the original size with minimal loss in quality; supports video resolutions up to 1080 HD." },
    { name: "dv", description: "A DV file is a video recorded by a digital video (DV) camera. It is saved to the camera's DV tape and uses a standard raw format for the DV video. Several video editing programs support the DV format." },
    { name: "f4v", description: "An F4V file is a video file saved in the video container format used by Adobe Flash. It stores video encoded in the H.264 codec, audio in the AAC+/HE-AAC/AAC v1/AAC v2 formats, and metadata. F4V files are primarily used to stream movies and TV shows online." },
    { name: "hevc", description: "An HEVC file contains a video stored in the High Efficiency Video Coding (HEVC) format. This format, also known as H.265, improves over the H.264 standard by allowing videos to be stored with a lower file size but with the same video quality." },
    { name: "m2v", description: "An M2V file is a video file encoded using MPEG-2 compression. It contains only video data and does not contain any audio or subtitles. M2V files are commonly used by DVD-authoring applications, which pair M2V files with audio files (such as .M2A and .WAV files) to create movies." },
    { name: "mod", description: "A MOD file is a music module file saved in the standard music module format, originating from the Amiga module format. It contains note patterns (similar to a .MIDI file) and sound samples representing different instruments played back according to the notes." },
    { name: "xvid", description: "Video clip or movie encoded using Xvid, an open-source video codec published under the GNU GPL license; compresses video data using MPEG-4 technology; allows for faster data transmission and more efficient storage of video files." },
    { name: "rmvb", description: "An RMVB file is a Real Media video file compressed using a variable bitrate, which is optimized for the content of the video. It is a newer variation of the standard Real Media (.RM) file format that allows for more efficient compression." },
    { name: "rm", description: "An RM file is a multimedia file saved in the RealMedia multimedia container format developed by RealNetworks. It may store only audio in the RealAudio format, only video in the RealVideo format, or both RealAudio and RealVideo." },
    { name: "ogv", description: "An OGV file is a video file saved in the Xiph.Org open-source Ogg container format. It contains video streams encoded with one or more codecs, such as Theora, Dirac, or Daala, and may or may not store audio streams." },
    { name: "mpg", description: "An MPG file is a common video file that uses a digital video format standardized by the Moving Picture Experts Group (MPEG). It stores video, audio, and metadata interleaved together. MPG files are often used for creating movies that are distributed on the Internet." },
    { name: "mpeg", description: "An MPEG file is a video file saved in a digital video format standardized by the Moving Picture Experts Group (MPEG). It contains video and audio data compressed with MPEG-1 or MPEG-2 compression and is often used to share videos over the Internet." },
    { name: "wtv", description: "The .wtv file format is associated with Windows Media Center and represents recorded TV shows or content. WTV stands for Windows Television. It is the primary file format used by Windows Media Center to store recorded TV programs." },
    { name: "video", description: "Video file created by aTube Catcher, a program used for downloading YouTube videos to the local computer; saves the raw video downloaded from YouTube; can be converted to various video formats such as .WMV, .AVI, .3G2, and .MP4." },
    { name: "m4s", description: "An M4S file is a segment of a video streamed using the MPEG-DASH video streaming technique. It contains binary data that represents a video segment." },
    { name: "movie", description: "Movie saved or formatted in Apple's proprietary QuickTime format; may use one of many types of codecs to compress the file, including the H.264 video codec, which uses a high-efficiency compression algorithm; H.264 support was introduced with QuickTime 7.0, which was released with Mac OS X 10.4 Tiger." },
    { name: "tvs", description: "A TVS file is a video file created by TeamViewer, a program that allows users to remotely control desktop computers. It contains video recorded from a remote session and is used for creating demonstration videos and recording session histories." },
    { name: "exo", description: "An EXO file is a video segment created by YouTube for Android. It contains a portion of a video that a user downloaded for offline playback. EXO files are encrypted, and they can be played only in certain versions of YouTube for Android." },
    { name: "cpi", description: "A CPI file is a metadata file that contains information about an .MTS video file. Various Sony and Panasonic high-definition digital video camcorders create CPI files alongside recorded videos. Usually, CPI files are stored in the ~AVCHD/​BDMV/​CLIPINF directory of a camcorder's SD card." },
    { name: "dash", description: "A DASH file is a video file created for high-quality video playback on sites such as YouTube and Netflix. It contains an HTTP-based segment of media content that is encoded with different bit rates, to keep a video playing through changing network conditions." },
    { name: "dmx", description: "A DMX file is an animation project created by Source Filmmaker (SFM), a tool that allows users to create animated movies using Valve Source engine game assets. It contains an editable movie set within a Team Fortress 2 map." },
    { name: "smi", description: "An SMI file is a disk image file created by Aladdin ShrinkWrap or Apple Disk Copy in Mac OS Classic (Mac OS 9 or earlier). It contains a disk image that mounts as a disk on the Desktop when opened." },
    { name: "wbmp", description: "Black and white bitmap image format used by mobile devices such as cell phones and PDAs; can be sent from one phone to another via the SMS messaging protocol; similar to a standard bitmap (.BMP) file, but only supports monochrome (1-bit) image data." },
    { name: "sfd", description: "Video file used by video games made for Sega Dreamcast, Nintendo GameCube, Microsoft Xbox, and other video game console systems; saves video in an uncommon Sofdec format that is not playable on standard video players; used for playing video game cinematic sequences." },
    { name: "cdda", description: "Audio file ripped from a CD formatted using the CD Digital Audio specification; can be extracted using applications such as iTunes or Logic Pro; used for saving CD audio data to a computer, as well as burning new CDs." },
    { name: "dbk", description: "A DBK file is a mobile phone backup file created by Sony Ericsson PC Suite, a program used to manage Sony Ericsson mobile phones. It contains multiple Zip-compression files that store various phone information such as the names and phone numbers of contacts." }
]