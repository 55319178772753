const inputReducers = (state = {input_file: []}, action) => {
    switch(action.type) {
        case "FIRST_INPUT": return {
            ...state,
            first_input: action.payload,
        }
        case "SECOND_INPUT": return {
            ...state,
            second_input: action.payload,
        }
        case "INPUT_FILE": return {
            ...state,
            input_file: action.payload,
        }
        default: return state;
    }
}

export default inputReducers;