export default [{
   "image": [
      "art",
      "arw",
      "avs",
      "bmp",
      "bpg",
      "brf",
      "cals",
      "cgm",
      "cr2",
      "crw",
      "cur ",
      "cut",
      "dcm",
      "dcr",
      "dcx",
      "dib",
      "dng",
      "emf",
      "fax",
      "gif",
      "hdr",
      "hrz",
      "ico",
      "info",
      "jng",
      "jpeg",
      "mat",
      "mng",
      "mono",
      "mpc",
      "mrw",
      "mtv",
      "mvg",
      "nef",
      "orf",
      "otb",
      "p7",
      "palm",
      "pam",
      "pbm",
      "pcx",
      "pes",
      "pfa",
      "pfb",
      "pfm",
      "pgm",
      "picon",
      "pict",
      "pix",
      "png",
      "pnm",
      "ppm",
      "psb",
      "psd",
      "ptif",
      "pwp",
      "rad",
      "raf",
      "rgf",
      "rla",
      "rle",
      "sct",
      "sfw",
      "sgi",
      "sun",
      "svg",
      "tga",
      "tiff",
      "tim",
      "ttf",
      "ubrl",
      "ubrl6",
      "uil",
      "uyvy",
      "vacar",
      "viff",
      "wbpm",
      "wpg",
      "x3f",
      "xbm",
      "xcf",
      "xmp",
      "xwd",
      "dxf",
      "eps",
      "pcd",
      "pct",
      "wmf",
      "jpg",
      "ai",
      "tif",
      "dst",
      "dds",
      "dwg",
      "raw",
      "webp",
      "cdr",
      "heic",
      "dfont",
      "ps",
      "nrw",
      "plt",
      "jfif",
      "heif",
      "apng",
      "avif",
      "cad",
      "psp",
      "pat",
      "pic",
      "cam",
      "vss",
      "srf",
      "dps",
      "mix",
      "pal",
      "muse",
      "vsd",
      "jpe",
      "jp2",
      "rgb",
      "jar",
      "mjpeg",
      "av1",
      "map",
      "jif",
      "shn",
      "tta",
      "ace",
      "afm",
      "wve",
      "cff",
      "wmz",
      "dss",
      "sd2",
      "rgba",
      "rw2",
      "cvs",
      "wbmp",
   ],
   "book": [
      "djvu",
      "fb2",
      "epub",
      "mobi",
      "azw3",
      "lit",
      "snb",
      "tcr",
      "azw",
      "prc"
   ],
   "document": [
      "html",
      "pdf",
      "shtml",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "pptx",
      "odt",
      "ott",
      "sxw",
      "stw",
      "docm",
      "xml",
      "wps",
      "dot",
      "hwp",
      "rtf",
      "txt",
      "wpd",
      "dotm",
      "pages",
      "lrf",
      "sdw",
      "ods",
      "ots",
      "sxc",
      "stc",
      "xlt",
      "csv",
      "wk1",
      "wks",
      "sdc",
      "dbf",
      "odp",
      "odg",
      "otp",
      "sxi",
      "sti",
      "pps",
      "pot",
      "sda",
      "sdd",
      "sdp",
      "pptm",
      "ppsx",
      "ppt",
      "dotx",
      "pdb",
      "xps",
      "oxps",
      "cbr",
      "cbz",
      "htm",
      "key",
      "numbers",
      "pub",
      "woff",
      "msg",
      "eml",
      "otf",
      "word",
      "bpm",
      "fig",
      "one",
      "jnt",
      "exp",
      "css",
      "cfm",
      "osm",
      "asd",
      "contact",
      "cal",
      "spc",
      "mac",
      "sec",
      "tax",
      "site",
      "style",
      "rtl",
      "olm",
      "max",
      "ind",
      "epf",
      "act",
      "fit",
      "sla",
      "sat",
      "bms",
      "cnc",
      "sam",
      "dex",
      "pez",
      "ipynb",
      "aspx",
      "dat",
      "dwf",
      "latex",
      "wv",
      "rb",
      "w64",
      "ac3",
      "ra",
      "d",
      "jps",
      "vqf",
      "exr",
      "dts",
      "gsm",
      "cab",
      "bin",
      "jbg",
      "vox",
      "xpm",
      "yuv",
      "abw",
      "potx",
      "ape",
      "deb",
      "sln",
      "sr2",
      "avr",
      "voc",
      "smp",
      "rpm"
   ],
   "archive": [
      "tar.bz2",
      "tar.gz",
      "rar",
      "tar",
      "tbz2",
      "tgz",
      "zip",
      "7z",
      "xz",
      "iso",
      "arc",
      "dbk"
   ],
   "audio": [
      "mp3",
      "wav",
      "m4a",
      "wma",
      "ogg",
      "flac",
      "aac",
      "aiff",
      "opus",
      "oga",
      "adts",
      "amr",
      "m4r",
      "alac",
      "caf",
      "m4p",
      "m4b",
      "midi",
      "xa",
      "weba",
      "all",
      "band",
      "ram",
      "als",
      "sid",
      "cfa",
      "cpr",
      "abc",
      "wax",
      "spx",
      "vivo",
      "mp2",
      "tak",
      "snd",
      "au",
      "ppsm",
      "kdc",
      "pef",
      "mod",
      "cdda",
   ],
   "video": [
      "mp4",
      "3gp",
      "avchd",
      "avi",
      "flv",
      "m2ts",
      "m4v",
      "mkv",
      "mov",
      "mts",
      "mxf",
      "swf",
      "ts",
      "vob",
      "webm",
      "wmv",
      "3g2",
      "aaf",
      "asf",
      "divx",
      "dv",
      "f4v",
      "hevc",
      "m2v",
      "xvid",
      "rmvb",
      "rm",
      "ogv",
      "mpg",
      "mpeg",
      "wtv",
      "m4s",
      "movie",
      "tvs",
      "exo",
      "cpi",
      "dash",
      "dmx",
      "smi",
      "sfd"
   ]
}
]
