import './App.css';
import React, { useRef, useState, useEffect, useMemo } from 'react'
import Home from './components/Home/Home';
import { useSelector } from "react-redux";
import { Routes, Route } from 'react-router';
import ImageConverter from './components/ImageConverter/ImageConverter';
import FormatConverter from './components/FormatConverter/FormatConverter';
import data from './assets/data'
import FileUploader from './components/FileUploader/FileUploader';


function App() {
  var location = window.location.pathname;
  const currentPath = location.includes("converter") ? location.slice(1).replace('-converter', '') : location.substring(1);
  const currentPathReplaced = currentPath || ""
  var fields = currentPathReplaced.split('-');
  var firstFileType = fields[0] || "";
  var secondFileType = fields[1] || "";

  useMemo(() => {
    ((firstFileType !== "document") && (firstFileType !== "audio") && (firstFileType !== "video") && (firstFileType !== "archive") && (firstFileType !== "ebook") && (firstFileType !== "image")) &&
      (sessionStorage.setItem("firstInput", firstFileType))
      sessionStorage.setItem("secondInput", secondFileType) 
  }, [location, firstFileType, secondFileType])
  
  const mode = useSelector(state => state.darkModeReducer)
  const images = data[0].image;
  const docs = data[0].document;
  const books = data[0].book;
  const archives = data[0].archive;
  const audios = data[0].audio;
  const videos = data[0].video;
  const mergedArrays = [].concat(images, docs, books, archives, audios, videos);
  const allRoutes = mergedArrays.map(item => mergedArrays.map(el => { return `<Route exact path={${item}-${el}} element={<FormatConverter />} />` }))
  let firstInput = sessionStorage.getItem("firstInput") || ""
  let secondInput =  sessionStorage.getItem("secondInput") || ""

  const newData = mergedArrays.map(item => {
    return `{name: "${item}", description: ""},\n`
  })

  console.log(allRoutes.flat(), "all the routes")

  return (
    <div className="App">
      <Routes>
      <Route exact path={`/${firstFileType}-${secondFileType}`} element={<FormatConverter />} />
        <Route path="/" element={<Home mode={mode} />} />
        {mergedArrays.map(item => {
          return <Route exact path={`${item}-converter`} element={<FormatConverter />} />
        })}
        {mergedArrays.map(item => {
          return <Route exact path={`${item}-${secondInput}`} element={<FormatConverter />} />
        })}
        {mergedArrays.map(item => {
          return <Route exact path={`${firstInput}-${item}`} element={<FormatConverter />} />
        })}
        <Route path="/image-converter" element={<ImageConverter />} />
        <Route path="/document-converter" element={<ImageConverter />} />
        <Route path="/audio-converter" element={<ImageConverter />} />
        <Route path="/video-converter" element={<ImageConverter />} />
        <Route path="/archive-converter" element={<ImageConverter />} />
        <Route path="/ebook-converter" element={<ImageConverter />} />
        <Route path="/fileuploader" element={<FileUploader />} />
        {mergedArrays.map(item => {
          return <Route exact path={`${item}-converter`} element={<FormatConverter />} />
        })}
        {mergedArrays.map(item => {
          return <Route exact path={`${item}-${secondInput}`} element={<FormatConverter />} />
        })}
        {mergedArrays.map(item => {
          return <Route exact path={`${firstInput}-${item}`} element={<FormatConverter />} />
        })}
        {/* {mergedArrays.map(item => {
          return <Route exact path={`${item}-${item}`} element={<FormatConverter />} />
        })}
        {mergedArrays.map(item => mergedArrays.map(el => { return `<Route exact path={${item}-${el}} element={<FormatConverter />} />` })).flat()} */}
      </Routes>
    </div>
  )
}

export default App;