import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import FileTypes from "../FileTypes/FileTypes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCirclePlus, faFile, faCloudArrowUp, faCircleXmark, faArrowRight, faCheck, faCloudArrowDown, faHeartCrack, faXmark, faX } from '@fortawesome/free-solid-svg-icons'
import { Audio, RevolvingDot } from 'react-loader-spinner'
import './FileUploader.scss'
import { useNavigate } from 'react-router';
import axios from 'axios'
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../actions";

function FileUploader() {
    const [convertTo, setConvertTo] = useState("")
    const mode = useSelector(state => state.darkModeReducer)
    const [convertedFile, setConvertedFile] = useState("")
    const [fileStatus, setFileStatus] = useState(false);
    const [showProgress, setShowProgress] = useState(false)
    const [showFileTypesConvert, setShowFileTypesConvert] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [error, setError] = useState(false)
    const [fileExtension, setFileExtension] = useState("")
    const url = "https://api-tasker.onlineconvertfree.com/api/upload";
    const api_key = "48619ec61669b8dc796436a64f71b866";
    const file = useSelector(state => state.inputReducers.input_file)
    const isMounted = useRef(false)
    const navigate = useNavigate();
    const dispatch = useDispatch()


    const handleConvertClick = () => {
        setShowFileTypesConvert(prev => !prev)
        if (convertedFile !== "") {
            setConvertedFile("");
        }
    }
    

    const onFileSelect = (e) => {
        setTimeout(() => {
                sessionStorage.setItem("secondInput", "")
                dispatch(allActions.inputActions.inputFile(e.target.files[0]))
                setFileExtension(e.target.files[0].name.split(".").pop());
                sessionStorage.setItem("firstInput", e.target.files[0].name.split(".").pop().toLowerCase())
                navigate(`/${e.target.files[0].name.split(".").pop().toLowerCase()}-converter`)
        }, 10)
    }

    useEffect(() => {

        if (!file || !convertTo) {
            return
        }
        axios.post(url,  { "file": file, "to": convertTo, "token": api_key }, { headers: { 'Content-Type': 'multipart/form-data' } },
        )
            .then(res => {
                console.log(res);
                setConvertedFile(res.data.CONVERTED_FILE);
                setShowProgress(false)
            })
            .catch(err => {
                console.log(err)
                setShowProgress(false)
                    setError(true)
                    setErrorMessage(`Sorry, can't convert your input file to ${convertTo.toUpperCase()} format`)
            })
    }, [convertTo])



    const onCrossClick = (e) => {
        dispatch(allActions.inputActions.inputFile([]))
        sessionStorage.clear();
        setConvertTo("")
        setShowFileTypesConvert(false)
        setShowProgress(false)
        setError(false)
        setErrorMessage("")
        setConvertedFile("");
        navigate("/")
    }

    function splitLastOccurrence(str, substring) {
        const lastIndex = str.lastIndexOf(substring);
        return str.slice(lastIndex + 1);
    }

    const onSecondCrossClick = () => {
        setError(false)
        setErrorMessage("")
    }

    const convertedFileName = splitLastOccurrence(convertedFile, "/")


    return (
        <>
            <div className="upload-container" style={mode ? {backgroundColor: '#6c757d'} : {}}>
                {file.length === 0 ? (
                    <>
                        <label for="files" class="btn" className="upload-button">
                            <FontAwesomeIcon icon={faFileCirclePlus} size="m" />
                            <span className="upload-text">Select File</span>
                        </label>
                        <input id="files" style={{ visibility: 'hidden' }} type="file" onChange={onFileSelect} />
                        <div className="drop-text" style={mode ? {color: '#ffff'} : {color: '#495057'}}><span className="drop-icon"><FontAwesomeIcon icon={faCloudArrowUp} size="m" /></span>Max file size - 100 MB</div>
                    </>
                ) : (<div className="files-and-button" style={mode ? {backgroundColor: '#111'} : {}}>
                    <div className="single-file" style={mode ? {color: '#adb5bd'} : {}}>
                        <div>
                            <span className="drop-icon"><FontAwesomeIcon icon={faFile} size="l" /></span>
                            <span className="file-name">{file.name.length < 40 ? file.name : file.name.slice(0, 13) + '...'}</span>
                            <button className="single-convert-button" onClick={handleConvertClick}>Convert to <span className="arrow-right"><FontAwesomeIcon icon={faArrowRight} size="m" /></span></button></div>
                        <span className="drop-icon-cross" onClick={onCrossClick} style={{ color: '#e5383b' }}><FontAwesomeIcon icon={faCircleXmark} size="xl" /></span>
                    </div>
                </div>
                )}
                {/* <button type="submit" className="submit-button" onClick={handleFileSubmit}>Convert to pdf</button> */}
                <div className="converted-file" style={mode ? {color: '#adb5bd'} : {}}>
                    {showFileTypesConvert && <span className="file-types-position"><FileTypes showFileTypesConvert={showFileTypesConvert} setShowFileTypesConvert={setShowFileTypesConvert} convertTo={convertTo} setConvertTo={setConvertTo} showProgress={showProgress} setShowProgress={setShowProgress}/></span>}
                    {
                        showProgress && (<><span className="progress-text">Converting</span>
                            <RevolvingDot
                                height="50"
                                width="50"
                                radius="12"
                                color="#4fa94d"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            /></>)}
                    {convertedFile && (<div className="single-file-completed" style={mode ? {backgroundColor: '#111', color: '#adb5bd'} : {}}><div className="filename-and-finishedPill"><span className="drop-icon"><FontAwesomeIcon icon={faFile} size="l" /></span><span>{convertedFileName.slice(0, 8) + "..."}</span></div>
                    <div className="download-and-x">
                    <span className="finished-text">DONE<FontAwesomeIcon icon={faCheck} size="m" style={{marginLeft: '0.3rem'}}/></span>
                    <button className="download-button" onClick={() => {window.open(convertedFile)}} style={{ color: 'white' }}>Download <span className="download-icon"><FontAwesomeIcon icon={faCloudArrowDown} size="l" /></span></button>
                    <FontAwesomeIcon icon={faX} size="m" className="x-icon" onClick={() => {setConvertedFile("")}}/>
                    </div>
                    </div>)}
                    {error === true && convertTo && errorMessage !== "" &&(<div className="error-message-box" style={mode ? {backgroundColor: '#111', color: '#adb5bd'} : {}}><span className="message-icon"><FontAwesomeIcon icon={faHeartCrack} size="xl"/></span><aside className="error-message">{errorMessage}</aside><span className="drop-icon-cross" onClick={onSecondCrossClick} style={{ color: '#e5383b', cursor: 'pointer' }}><FontAwesomeIcon icon={faXmark} size="xl" /></span></div>)}
                </div>
            </div>
        </>
    );
}

export default FileUploader;