import React, { useEffect, useState } from 'react'
import './ImageConverter.scss'
import { useSelector, useDispatch } from "react-redux";
import allActions from '../../actions'
import { Link } from 'react-router-dom'
import Header from '../Header/Header'
import FileTypes from '../FileTypes/FileTypes';
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAudio, faVideo, faFile, faImage, faBoxArchive, faBook, faCaretDown, faCaretUp, faMobileScreen, faFileLines, faShield, faBoltLightning, faArrowsSpin } from '@fortawesome/free-solid-svg-icons'
import FileUploader from '../FileUploader/FileUploader';
import data from '../../assets/data'
import Footer from '../Footer/Footer';
import { Helmet } from 'react-helmet'

function ImageConverter(props) {

    const dispatch = useDispatch();
    const location = useLocation();
    sessionStorage.clear();
    const currentPath = location.pathname.slice(1);
    const file = sessionStorage.getItem("file")
    const [showFileTypes, setShowFileTypes] = useState(false);
    const [showFileTypesTwo, setShowFileTypesTwo] = useState(false);
    const menu = useSelector(state => state.menuReducer)
    const mode = useSelector(state => state.darkModeReducer)
    let firstInput = sessionStorage.getItem("firstInput") || ""
    let secondInput = sessionStorage.getItem("secondInput") || ""
    const images = data[0].image;
    const docs = data[0].document;
    const books = data[0].book;
    const archives = data[0].archive;
    const audios = data[0].audio;
    const videos = data[0].video;

    const onItemClick = () => {
        dispatch(allActions.menuActions())
        sessionStorage.setItem(secondInput, "")
    }

    const handleShowFileTypes = () => {
        setShowFileTypes(!showFileTypes)
        setShowFileTypesTwo(false)
    }
    const handleShowFileTypesTwo = () => {
        setShowFileTypesTwo(!showFileTypesTwo)
        setShowFileTypes(false)
    };

    useEffect(() => {
        dispatch(allActions.inputActions.inputFile([]))
    }, [])

    let fileFormats;
    let metaTags;
    let bannerText;
    let fileType;

    switch (currentPath) {
        case "image-converter":
            fileFormats = (<div className="file-types-list">{images.map((item) => (<span className="item" onClick={() => { sessionStorage.setItem("firstInput", item) }}><Link onClick={() => { sessionStorage.removeItem("secondInput") }} to={`/${item}-converter`} style={mode ? { textDecoration: 'none', color: '#c0c0c0' } : { textDecoration: 'none' }}>{item}-converter</Link></span>))}</div>);
            metaTags = (<Helmet>
                <html lang="en" />
                <title>Free Online Image Files Converter - FilesMonkey</title>
                <meta name="description" content="Convert your image files free online with FilesMonkey" />
            </Helmet>)
            bannerText = "Convert your image files for free with FilesMonkey. You can choose any format of your choice from JPEG, PNG, BMP, GIF, SVG, TIFF and many others."
            fileType = "Image"
            break;
        case "document-converter":
            fileFormats = (<div className="file-types-list">{docs.map((item) => (<span className="item" onClick={() => { sessionStorage.setItem("firstInput", item) }}><Link to={`/${item}-converter`} style={mode ? { textDecoration: 'none', color: '#c0c0c0' } : { textDecoration: 'none' }}>{item}-converter</Link></span>))}</div>);
            metaTags = (<Helmet>
                <html lang="en" />
                <title>Free Online Document Files Converter - FilesMonkey</title>
                <meta name="description" content="convert your document files free online with FilesMonkey" />
            </Helmet>)
            bannerText = "Convert your document files for free with FilesMonkey. You can choose any format of your choice from DOC, DOCX, PDF, PPTX, HTML, XML and many others."
            fileType = "Document"
            break;
        case "audio-converter":
            fileFormats = (<div className="file-types-list">{audios.map((item) => (<span className="item" onClick={() => { sessionStorage.setItem("firstInput", item) }}><Link to={`/${item}-converter`} style={mode ? { textDecoration: 'none', color: '#c0c0c0' } : { textDecoration: 'none' }}>{item}-converter</Link></span>))}</div>);
            metaTags = (<Helmet>
                <html lang="en" />
                <title>Free Online Audio Files Converter - FilesMonkey</title>
                <meta name="description" content="Convert your audio files free online with FilesMonkey" />
            </Helmet>)
            bannerText = "Convert your audio files for free with FilesMonkey. You can choose any format of your choice from MP3, AAC, ALAC, FLAC, WAV and many others."
            fileType = "Audio"
            break;
        case "video-converter":
            fileFormats = (<div className="file-types-list">{videos.map((item) => (<span className="item" onClick={() => { sessionStorage.setItem("firstInput", item) }}><Link to={`/${item}-converter`} style={mode ? { textDecoration: 'none', color: '#c0c0c0' } : { textDecoration: 'none' }}>{item}-converter</Link></span>))}</div>);
            metaTags = (<Helmet>
                <html lang="en" />
                <title>Free Online Video Files Converter - FilesMonkey</title>
                <meta name="description" content="Convert your video files free online with FilesMonkey" />
            </Helmet>)
            bannerText = "Convert your video files for free with FilesMonkey. You can choose any format of your choice from MP4, MOV, AVI, WVM, FLV, WebM, MKV and many others"
            fileType = "Video"
            break;
        case "archive-converter":
            fileFormats = (<div className="file-types-list">{archives.map((item) => (<span className="item" onClick={() => { sessionStorage.setItem("firstInput", item) }}><Link to={`/${item}-converter`} style={mode ? { textDecoration: 'none', color: '#c0c0c0' } : { textDecoration: 'none' }}>{item}-converter</Link></span>))}</div>);
            metaTags = (<Helmet>
                <html lang="en" />
                <title>Free Online Archive Files Converter - FilesMonkey</title>
                <meta name="description" content="Convert your archive files free online with FilesMonkey" />
            </Helmet>)
            bannerText = "Convert your archive files for free with FilesMonkey. You can choose any format of your choice from ZIP, RAR, Tar.GZ, 7Z, JAR, TGZ and many others"
            fileType = "Archive"
            break;
        case "ebook-converter":
            fileFormats = (<div className="file-types-list">{books.map((item) => (<span className="item" onClick={() => { sessionStorage.setItem("firstInput", item) }}><Link to={`/${item}-converter`} style={mode ? { textDecoration: 'none', color: '#c0c0c0' } : { textDecoration: 'none' }}>{item}-converter</Link></span>))}</div>);
            metaTags = (<Helmet>
                <html lang="en" />
                <title>Free Online Ebook Files Converter - FilesMonkey</title>
                <meta name="description" content="Convert your Ebook files free online with FilesMonkey" />
            </Helmet>)
            bannerText = "Convert your ebook files for free with FilesMonkey. You can choose any format of your choice from DJVU, TCR, MOBI, PRC and many others"
            fileType = "Ebook"
            break;

        default: fileFormats = ("");
            metaTags = ("");
    }

    const content = (<div className="menu-container" style={mode ? { backgroundColor: '#13315c' } : { backgroundColor: '#0582ca' }}>
        <div className="first-row">
            <div className="menu-item" onClick={onItemClick}><Link to="/image-converter" style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faImage} size="xl" /><span className="type-text">Image Converter</span></Link></div>
            <div className="menu-item" onClick={onItemClick}><Link to="/document-converter" style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faFile} size="xl" /><span className="type-text">Document Converter</span></Link></div>
            <div className="menu-item" onClick={onItemClick}><Link to="/audio-converter" style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faFileAudio} size="xl" /><span className="type-text">Audio Converter</span></Link></div>
        </div>
        <div className="second-row">
            <div className="menu-item" onClick={onItemClick}><Link to="/video-converter" style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faVideo} size="xl" /><span className="type-text">Video Converter</span></Link></div>
            <div className="menu-item" onClick={onItemClick}><Link to="/archive-converter" style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faBoxArchive} size="xl" /><span className="type-text">Archive Converter</span></Link></div>
            <div className="menu-item" onClick={onItemClick}><Link to="/ebook-converter" style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faBook} size="xl" /><span className="type-text">Ebook Converter</span></Link></div>
        </div>
    </div>);
    return (
        <>
            {metaTags}
            <div className="sticky">
                <Header />
            </div>
            {menu ? (content) : ""}
            <div className="main-container">
                {!menu ? (<div className="banner" style={mode ? { backgroundColor: '#13315c' } : { backgroundColor: '#0582ca' }}>
                    <div className="first-column">
                        <h1 className="file-type">Free Online {fileType} Converter</h1>
                        <p className="banner-text">
                            {bannerText}
                        </p>
                    </div>
                    <div className="second-column">
                        {showFileTypes || showFileTypesTwo ? (<div className="nav-menu"><FileTypes showFileTypes={showFileTypes} setShowFileTypes={setShowFileTypes} showFileTypesTwo={showFileTypesTwo} setShowFileTypesTwo={setShowFileTypesTwo} /></div>) : ""}
                        <span className="convert-text">Convert</span>
                        {showFileTypes ? (<button className="dropdown-list" onClick={handleShowFileTypes}><FontAwesomeIcon icon={faCaretUp} size="xl" /><span className="small-text">{!firstInput ? "..." : firstInput.toUpperCase()}</span></button>) : (<button className="dropdown-list" onClick={handleShowFileTypes}><FontAwesomeIcon icon={faCaretDown} size="xl" /><span className="small-text">{!firstInput ? "..." : firstInput.toUpperCase()}</span></button>)}
                        <span className="convert-text">to</span>
                        {showFileTypesTwo ? (<button className="dropdown-list" onClick={handleShowFileTypesTwo}><FontAwesomeIcon icon={faCaretUp} size="xl" /><span className="small-text">{!secondInput ? "..." : secondInput.toUpperCase()}</span></button>) : (<button className="dropdown-list" onClick={handleShowFileTypesTwo}><FontAwesomeIcon icon={faCaretDown} size="xl" /><span className="small-text">{!secondInput ? "..." : secondInput.toUpperCase()}</span></button>)}
                    </div>
                </div>) : ""}
                <div><FileUploader /></div>
                <div className="formats-list" style={mode ? { color: 'white', backgroundColor: '#495057' } : { color: '#333533', backgroundColor: '#f8f9fa' }}>
                    <div className="file-type-heading">
                        <span className="spin-icon"><FontAwesomeIcon icon={faArrowsSpin} size="xl" /></span>
                        <span className="file-type-heading-text">{fileType} Converters</span>
                    </div>
                    {fileFormats}
                </div>
                <div className="features-box" style={mode ? { backgroundColor: '#343a40' } : { backgroundColor: '#e9ecef' }}>
                    <div className="features-first-row">
                        <div className="content-box">
                            <span className="icon" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}><FontAwesomeIcon icon={faFileLines} size="xl" /></span>
                            <span className="heading" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}>
                                300+ formats supported
                            </span>
                            <p style={mode ? { color: '#c0c0c0' } : { color: '#212529' }}>
                                We support more than 300 file formats. You can convert from any one of those to any of the file format of your choice.
                            </p>
                        </div>
                        <div className="content-box">
                            <span className="icon" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}><FontAwesomeIcon icon={faShield} size="xl" /></span>
                            <span className="heading" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}>
                                Data Security
                            </span>
                            <p style={mode ? { color: '#c0c0c0' } : { color: '#212529' }}>
                                Your uploaded and converted files are deleted after 24 hours automatically and irreversibly. This implies that you can delete your files manually on your own anytime before they are deleted automatically.
                            </p>
                        </div>
                    </div>
                    <div className="features-first-row">
                        <div className="content-box">
                            <span className="icon" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}><FontAwesomeIcon icon={faBoltLightning} size="xl" /></span>
                            <span className="heading" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}>
                                Lightning fast conversions
                            </span>
                            <p style={mode ? { color: '#c0c0c0' } : { color: '#212529' }}>
                                You will definitely feel it when you use our application. This app helps you convert your files at blazing fast speed and helps you save lots of time.
                            </p>
                        </div>
                        <div className="content-box">
                            <span className="icon" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}><FontAwesomeIcon icon={faMobileScreen} size="xl" /></span>
                            <span className="heading" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}>
                                All screens supported
                            </span>
                            <p style={mode ? { color: '#c0c0c0' } : { color: '#212529' }}>
                                FilesMonkey is a browser based application and you do not need to install any other additional software. You can access it from any of your devices using your web browser.
                            </p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default React.memo(ImageConverter)