import React, { useState } from 'react'
import './Header.scss';
import logo from '../../../src/assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { faMoon } from '@fortawesome/free-regular-svg-icons'
import { faMoon as filledMoon } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from "react-redux";
import allActions from '../../actions'
import { Link } from 'react-router-dom';

function Header() {

  const mode = useSelector(state => state.darkModeReducer)
  const menu = useSelector(state => state.menuReducer)
  const dispatch = useDispatch();

  const onBrandClick = (e) => {
    dispatch(allActions.inputActions.inputFile([]));
    sessionStorage.clear();
  }

  return (
    <div className='header' style={mode ? { color: 'white', backgroundColor: '#0b2545' } : { color: 'black', backgroundColor: 'white' }}>
      <div className='brand'>
        <Link to={{pathname: "/", state: {fileType: "File", bannerText: "FilesMonkey is a free online file converter. Our app supports all types of image, document, video, audio, Ebook and archive formats which you can use to convert to any other format of your choice for absolutely free."}}}>
          <img src={logo} alt="logo" className='logo' onClick={onBrandClick}/>
        </Link>
        <p className="brand-name">FilesMonkey</p>
        <span className='tools-link' onClick={() => dispatch(allActions.menuActions())} style={mode ? { color: 'white', backgroundColor: '#0b2545' } : { color: '#0582ca', backgroundColor: 'white' }}>Tools {menu ? (<FontAwesomeIcon icon={faAngleUp} />) : (<FontAwesomeIcon icon={faAngleDown} />)}</span>
      </div>
      <div className='dark-mode-toggle' onClick={() => dispatch(allActions.darkModeActions())}>
        <span className="dark-mode"> {mode ? (<FontAwesomeIcon icon={filledMoon} />) : (<FontAwesomeIcon icon={faMoon} />)} <span className="dark-mode-text">Dark Mode</span></span>
      </div>
    </div>
  )
}

export default Header