import React from 'react'
import './Footer.scss'

function Footer() {


    return (
        <div className="container">
            <span className="copyright"> &copy; 2023 filesmonkey.com</span>
            <span className="language-toggle">{window.navigator.language}</span>
        </div>
    )
}

export default Footer