import React, { useRef, useState, useEffect, useMemo } from 'react'
import './FormatConverter.scss'
import { useSelector, useDispatch } from "react-redux";
import allActions from '../../actions'
import { Link } from 'react-router-dom'
import data from '../../assets/data'
import Header from '../Header/Header'
import FileTypes from '../FileTypes/FileTypes';
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAudio, faVideo, faFile, faImage, faBoxArchive, faBook, faCaretDown, faCaretUp, faMobileScreen, faFileLines, faShield, faBoltLightning } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import Footer from '../Footer/Footer';
import { Helmet } from 'react-helmet';
import newData from '../../assets/newData'
import FileUploader from '../FileUploader/FileUploader';
import { useNavigate } from 'react-router';


function FormatConverter(props) {

    const dispatch = useDispatch();
    var location = window.location.pathname;
    const currentPath = location.includes("converter") ? location.slice(1).replace('-converter', '') : location.substring(1);
    const currentPathReplaced = currentPath || "";
    let firstInput = sessionStorage.getItem("firstInput") || currentPath;
    let secondInput = sessionStorage.getItem("secondInput") || ""
    const fileType = location.state?.fileType || "File";
    const rapidApiKey = "95235a6304mshc7ffd6dce93761ep18a31fjsne2f1ae273108";
    const rapidApiHost = "file-extension.p.rapidapi.com";
    const rapidApiUrl = "https://file-extension.p.rapidapi.com/details";
    const [showFileTypes, setShowFileTypes] = useState(false);
    const [showFileTypesTwo, setShowFileTypesTwo] = useState(false);
    const [firstFileFull, setFirstFileFull] = useState("");
    const [secondFileFull, setSecondFileFull] = useState("");
    const [firstFileDescription, setFirstFileDescription] = useState("");
    const [secondFileDescription, setSecondFileDescription] = useState("");
    const menu = useSelector(state => state.menuReducer)
    const mode = useSelector(state => state.darkModeReducer)
    const navigate = useNavigate()
    let converterType;
    if (secondInput) {
        converterType = `${firstInput.toUpperCase()} to ${secondInput.toUpperCase()}`
    } else {
        converterType = firstInput.toUpperCase();
    }


    var fields = currentPathReplaced.split('-');
    var firstFileType = fields[0] || "";
    var secondFileType = fields[1] || "";

    useMemo(() => {
        ((firstFileType !== "document") && (firstFileType !== "audio") && (firstFileType !== "video") && (firstFileType !== "archive") && (firstFileType !== "ebook") && (firstFileType !== "image")) &&
            sessionStorage.setItem("firstInput", firstFileType)
        sessionStorage.setItem("secondInput", secondFileType)
    }, [location, firstFileType, secondFileType])

    console.log(firstInput, secondInput, "inputs")



    const images = data[0].image;
    const docs = data[0].document;
    const books = data[0].book;
    const archives = data[0].archive;
    const audios = data[0].audio;
    const videos = data[0].video;
    let currentFileType = "";
    console.log(newData[0])


    useEffect(() => {
        const found = newData.find(item => item.name === firstInput)
        if (found)
            setFirstFileDescription(found.description)
    }, [firstInput, firstFileFull])

    useEffect(() => {
        const found = newData.find(item => item.name === secondInput)
        if (found)
            setSecondFileDescription(found.description)
    }, [secondInput, secondFileFull])

    let bannerText;
    if (images.includes(firstInput || secondInput)) {
        bannerText = "Convert your image files for free with FilesMonkey. You can choose any format of your choice from JPEG, PNG, BMP, GIF, SVG, TIFF and many others."
        currentFileType = "Image";
    } else if (docs.includes(firstInput || secondInput)) {
        bannerText = "Convert your document files for free with FilesMonkey. You can choose any format of your choice from DOC, DOCX, PDF, PPTX, HTML, XML and many others."
        currentFileType = "Document"
    } else if (audios.includes(firstInput || secondInput)) {
        bannerText = "Convert your audio files for free with FilesMonkey. You can choose any format of your choice from MP3, AAC, ALAC, FLAC, WAV and many others."
        currentFileType = "Audio"
    } else if (videos.includes(firstInput || secondInput)) {
        bannerText = "Convert your video files for free with FilesMonkey. You can choose any format of your choice from MP4, MOV, AVI, WVM, FLV, WebM, MKV and many others"
        currentFileType = "Video"
    } else if (archives.includes(firstInput || secondInput)) {
        bannerText = "Convert your archive files for free with FilesMonkey. You can choose any format of your choice from ZIP, RAR, Tar.GZ, 7Z, JAR, TGZ and many others"
        currentFileType = "Archive"
    } else if (books.includes(firstInput || secondInput)) {
        bannerText = "Convert your ebook files for free with FilesMonkey. You can choose anyy format of your choice You can choose any format of your choice from DJVU, TCR, MOBI, PRC and many others"
        currentFileType = "Ebook"
    }

    const handleShowFileTypes = () => {
        setShowFileTypes(!showFileTypes)
        setShowFileTypesTwo(false)
    }
    const handleShowFileTypesTwo = () => {
        setShowFileTypesTwo(!showFileTypesTwo)
        setShowFileTypes(false)
    };

    const options = {
        method: 'GET',
        url: 'https://file-extension.p.rapidapi.com/details',
        params: { extension: sessionStorage.getItem("firstInput") },
        headers: {
            'X-RapidAPI-Key': '95235a6304mshc7ffd6dce93761ep18a31fjsne2f1ae273108',
            'X-RapidAPI-Host': 'file-extension.p.rapidapi.com'
        }
    };


    useEffect(() => {
        if(!firstInput) {
            return
        }
        setTimeout(() => {
            axios(options)
                .then((response) => {
                    setFirstFileFull(response.data)
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                })
        }, 10)
    }, [firstInput, location]);

    const options2 = {
        method: 'GET',
        url: 'https://file-extension.p.rapidapi.com/details',
        params: { extension: sessionStorage.getItem("secondInput") },
        headers: {
            'X-RapidAPI-Key': '95235a6304mshc7ffd6dce93761ep18a31fjsne2f1ae273108',
            'X-RapidAPI-Host': 'file-extension.p.rapidapi.com'
        }
    };

    useEffect(() => {
        if(!secondInput) {
            return
        }
        setTimeout(() => {
            axios(options2)
                .then((response) => {
                    setSecondFileFull(response.data)
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                })
        }, 10)
    }, [secondInput, location]);

    console.log(firstInput, secondInput, "inputs")




    const content = (<div className="menu-container" style={mode ? { backgroundColor: '#13315c' } : { backgroundColor: '#0582ca' }}>
        <div className="first-row">
            <div className="menu-item" onClick={() => dispatch(allActions.menuActions())}><Link to="/image-converter" style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faImage} size="xl" /><span className="type-text">Image Converter</span></Link></div>
            <div className="menu-item" onClick={() => dispatch(allActions.menuActions())}><Link to="/document-converter" style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faFile} size="xl" /><span className="type-text">Document Converter</span></Link></div>
            <div className="menu-item" onClick={() => dispatch(allActions.menuActions())}><Link to="/audio-converter" style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faFileAudio} size="xl" /><span className="type-text">Audio Converter</span></Link></div>
        </div>
        <div className="second-row">
            <div className="menu-item" onClick={() => dispatch(allActions.menuActions())}><Link to="/video-converter" style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faVideo} size="xl" /><span className="type-text">Video Converter</span></Link></div>
            <div className="menu-item" onClick={() => dispatch(allActions.menuActions())}><Link to="/archive-converter" style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faBoxArchive} size="xl" /><span className="type-text">Archive Converter</span></Link></div>
            <div className="menu-item" onClick={() => dispatch(allActions.menuActions())}><Link to="/ebook-converter" style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faBook} size="xl" /><span className="type-text">Ebook Converter</span></Link></div>
        </div>
    </div>);

    return (
        <>
            {secondInput !== "" ? (<Helmet>
                <html lang="en" />
                <title>Free Online {firstFileType?.toUpperCase()} to {secondInput?.toUpperCase()} Converter - FilesMonkey</title>
                <meta name="description" content={`convert your ${firstFileType} files to ${secondFileType} format free online with FilesMonkey`} />
            </Helmet>)
                : (<Helmet>
                    <html lang="en" />
                    <title>Best Free {firstInput.toUpperCase()} Converter | Convert to and from {firstInput.toUpperCase()} Online</title>
                    <meta name="description" content={`convert your ${currentPathReplaced} files to any ${currentFileType} format free onine with FilesMonkey`} />
                </Helmet>)
            }
            <div className="sticky">
                <Header />
            </div>
            {menu ? (content) : ""}
            <div className="main-container">
                {!menu ? (<div className="banner" style={mode ? { backgroundColor: '#13315c' } : { backgroundColor: '#0582ca' }}>
                    <div className="first-column">
                        <h1 className="file-type">{secondInput ? `Convert ${firstInput.toUpperCase()} to ${secondInput.toUpperCase()} Online` : `Free Online ${firstInput.toUpperCase()} Converter`}</h1>
                        <p className="banner-text">
                            {bannerText}
                        </p>
                    </div>
                    <div className="second-column">
                        {showFileTypes || showFileTypesTwo ? (<div className="nav-menu"><FileTypes showFileTypes={showFileTypes} setShowFileTypes={setShowFileTypes} showFileTypesTwo={showFileTypesTwo} setShowFileTypesTwo={setShowFileTypesTwo} /></div>) : ""}
                        <span className="convert-text">Convert</span>
                        {showFileTypes ? (<button style={showFileTypes ? { backgroundColor: '#006494' } : ""} className="dropdown-list" onClick={handleShowFileTypes}><FontAwesomeIcon icon={faCaretUp} size="xl" /><span className="small-text">{!firstInput ? "..." : firstInput.toUpperCase()}</span></button>) : (<button className="dropdown-list" onClick={handleShowFileTypes}><FontAwesomeIcon icon={faCaretDown} size="xl" /><span className="small-text">{!firstInput ? "..." : firstInput.toUpperCase()}</span></button>)}
                        <span className="convert-text">to</span>
                        {showFileTypesTwo ? (<button style={showFileTypesTwo ? { backgroundColor: '#006494' } : ""} className="dropdown-list" onClick={handleShowFileTypesTwo}><FontAwesomeIcon icon={faCaretUp} size="xl" /><span className="small-text">{!secondInput ? "..." : secondInput.toUpperCase()}</span></button>) : (<button className="dropdown-list" onClick={handleShowFileTypesTwo}><FontAwesomeIcon icon={faCaretDown} size="xl" /><span className="small-text">{!secondInput ? "..." : secondInput.toUpperCase()}</span></button>)}
                    </div>
                </div>) : ""}
                <div><FileUploader /></div>
                <div className="files-definition-container" style={!secondInput && mode ? { maxHeight: '40vh', backgroundColor: '#4d5154' } : secondInput && mode ? { maxHeight: '80vh', backgroundColor: '#4d5154' }  : !secondInput && !mode ? {maxHeight: '40vh'} : {}}>
                    <div className="first-file-definition" style={!secondInput && mode ? { minWidth: '80vw', backgroundColor: '#111' } : secondInput && mode ? { maxWidth: '43vw', backgroundColor: '#111' } : !secondInput && !mode ? { minWidth: '80vw' } : secondInput && !mode ? { maxWidth: '43vw' } : {}}>
                        <h4 style={mode ? { color: '#adb5bd' } : {}}><span style={{ letterSpacing: "0.1rem" }}>{firstInput.toUpperCase()}</span> - {firstFileFull.fullName || ""}</h4>
                        {firstFileFull && <img className="file-type-icon" src={firstFileFull.icons.normal} alt="First file icon" />}
                        <p className="file-definition-para" style={mode ? { color: '#adb5bd' } : {}}>{firstFileDescription || ""}</p>
                    </div>
                    {secondInput && (<div className="second-file-definition" style={mode ? { backgroundColor: '#111' } : {}}>
                        <h4 style={mode ? { color: '#adb5bd' } : {}}><span style={{ letterSpacing: "0.1rem" }}>{secondInput.toUpperCase()}</span> - {secondFileFull.fullName || ""}</h4>
                        {secondFileFull && <img className="file-type-icon" src={secondFileFull.icons.normal} alt="Second file icon" />}
                        <p className="file-definition-para" style={mode ? { color: '#adb5bd' } : {}}>{secondFileDescription || ""}</p>
                    </div>)}
                </div>
                <div className="features-box" style={mode ? { backgroundColor: '#343a40' } : { backgroundColor: '#e9ecef' }}>
                    <div className="features-first-row">
                        <div className="content-box">
                            <span className="icon" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}><FontAwesomeIcon icon={faFileLines} size="xl" /></span>
                            <span className="heading" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}>
                                300+ formats supported
                            </span>
                            <p style={mode ? { color: '#c0c0c0' } : { color: '#212529' }}>
                                We support more than 300 file formats. You can convert from any one of those to any of the file format of your choice.
                            </p>
                        </div>
                        <div className="content-box">
                            <span className="icon" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}><FontAwesomeIcon icon={faShield} size="xl" /></span>
                            <span className="heading" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}>
                                Data Security
                            </span>
                            <p style={mode ? { color: '#c0c0c0' } : { color: '#212529' }}>
                                Your uploaded and converted files are deleted after 24 hours automatically and irreversibly. This implies that you can delete your files manually on your own anytime before they are deleted automatically.
                            </p>
                        </div>
                    </div>
                    <div className="features-first-row">
                        <div className="content-box">
                            <span className="icon" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}><FontAwesomeIcon icon={faBoltLightning} size="xl" /></span>
                            <span className="heading" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}>
                                Lightning fast conversions
                            </span>
                            <p style={mode ? { color: '#c0c0c0' } : { color: '#212529' }}>
                                You will definitely feel it when you use our application. This app helps you convert your files at blazing fast speed and helps you save lots of time.
                            </p>
                        </div>
                        <div className="content-box">
                            <span className="icon" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}><FontAwesomeIcon icon={faMobileScreen} size="xl" /></span>
                            <span className="heading" style={mode ? { color: '#c0c0c0' } : { color: '#333533' }}>
                                All screens supported
                            </span>
                            <p style={mode ? { color: '#c0c0c0' } : { color: '#212529' }}>
                                FilesMonkey is a browser based application and you do not need to install any other additional software. You can access it from any of your devices using your web browser.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default React.memo(FormatConverter)