import darkModeActions from "./darkModeActions";
import menuActions from "./menuActions";
import inputActions from "./inputActions";

const allActions = {
    darkModeActions,
    menuActions,
    inputActions
}

export default allActions;